const CollecionTime: Record<string, number> = {
  '0x80176eae051ffaaf0b015fecdc7217aaa31b5523': 1666656000,
  '0x4840c0e29764c2b18e27ef1b666394d088543fb2': 1667174400,
  '0xa94589c111779a86fe88c9a3e97c6aaf4e7ec5fc': 1667865600,
  '0x268e700e0b1ad7d8e56af36ed8bbb5b7ea0e82d4': 1668211200
}

export const getCustomCollectionTime = (contract: string): number => {
  return CollecionTime[contract] ?? undefined
}
