// import 'swiper/swiper-bundle.css'

import { animated, useSpring, useSpringRef, useSprings } from '@react-spring/web'
import TokenImage from 'components/TokenImage'
import { getCustomCollectionTime } from 'constants/collections'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAllProjectsQuery } from 'state/data/enhanced'
import { useAppSelector } from 'state/hooks'
import SwiperCore, { EffectCoverflow, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, EffectCoverflow, Mousewheel])
export default function Home() {
  const [showNavBtn, setShowNavBtn] = useState(false)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const { data, refetch } = useAllProjectsQuery({
    first: 10,
    skip: 0
  })
  const [loadedImage, setLoadedImage] = useState([] as any)
  const chainId = useAppSelector((state) => state.application.chainId)
  const history = useHistory()
  useEffect(() => {
    if (chainId) refetch()
  }, [chainId, refetch])

  const charsArr = 'A.I ART ON THE BLOCKCHAIN'
  const [items] = useState<string[]>(charsArr.split(''))

  const to = (i: number) => ({
    y: 0,
    opacity: 1,
    delay: 300 + i * 50
  })
  const from = (_i: number) => ({ y: 100, opacity: 0 })

  const [springs, api] = useSprings(items.length, (i) => ({
    ...to(i),
    from: from(i),
    reset: true,
    onRest: {
      y: ({ value }) => {
        if (value === 100) {
          if (i === items.length - 1) {
            api.start((i) => to(i))
          }
        } else {
          if (i === items.length - 1) {
            api.start((i) => from(i))
          }
        }
      }
    }
  }))

  const displayRef = useSpringRef()
  const prop = useSpring({
    ref: displayRef,
    from: { height: '100%' },
    to: { height: '0%' },
    config: { duration: 1500 }
  })
  // useChain([trailRef, displayRef], [0, 5])

  useEffect(() => {
    if (loadedImage.length >= data?.projects?.length) {
      displayRef.start()
    }
  }, [data?.projects?.length, displayRef, loadedImage])

  useEffect(() => {
    setTimeout(() => {
      if (displayRef) {
        displayRef.start()
      }
    }, 10000)
  }, [displayRef])

  return (
    <>
      <animated.div className="preloader" style={prop}>
        <div className="preloader__inner">
          <div className="container is--hero">
            <div className="heading-wrap">
              <animated.h1 className="animate-this-shit">
                {/* {trail.map(({ ...style }, index) => (
                  <animated.span key={index} style={style}>
                    {items[index]}
                  </animated.span>
                ))} */}
                {springs.map(({ y, opacity }, index) => (
                  <animated.span key={index} style={{ y, opacity }}>
                    {items[index]}
                  </animated.span>
                ))}
              </animated.h1>
            </div>
          </div>
        </div>
      </animated.div>
      <div className="page-background">
        <div className="bg-blur _1">
          <div className="bg-blur inner _1"></div>
        </div>
      </div>
      <div className="container-2 w-container">
        <h1 className="text-block-2">
          A.I ART ON THE BLOCKCHAIN
          <br />
        </h1>
        <div
          onClick={() => {
            history.push('/collection')
          }}
          className="button-5 w-button"
        >
          Explore
        </div>
        <div
          onClick={() => {
            history.push('/submit')
          }}
          className="button-5 sell w-button"
        >
          Sell
        </div>
      </div>
      <div className="hero">
        <div className="clone-the-child-of-this">
          <div id="wheel-slider" className="wheel-slider">
            {data && data.projects && data.projects.length > 0 && (
              <Swiper
                effect={'coverflow'}
                centeredSlides={true} // Force centered slide
                slidesPerView={'auto'} // auto = the slide itself defined the viewport of the slider
                allowTouchMove={true} // Allows more control options except Slide Next and Slide Prev
                speed={1000} // Speed of the change animation
                loop={true} // Looping the slides
                mousewheel={true} // control the slider with mousewheel
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 150,
                  modifier: 1,
                  slideShadows: true
                }}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current
                }}
                className="swiper-container"
              >
                <div className="swiper-wrapper">
                  {data.projects.map((item: any, index: any) => {
                    return (
                      <SwiperSlide key={index} className="swiper-slide">
                        <div
                          className="slide-content w-inline-block"
                          onClick={() => {
                            history.push(`/detail/${item.id}`)
                          }}
                        >
                          <TokenImage
                            loading="lazy"
                            sizes="45vw"
                            alt=""
                            uri={item.baseUri + item.tokens[0].tokenId}
                            className="slide-image"
                            onLoad={(uri: string) => {
                              if (loadedImage.indexOf(uri) === -1) setLoadedImage(loadedImage.concat(uri))
                            }}
                          ></TokenImage>

                          <div className="slide-inner">
                            <div className="slide-text-wrap">
                              <h2 className="slide-heading">{item.name}</h2>
                              {(getCustomCollectionTime(item.tokenContract) ?? item.timer) >
                                new Date().getTime() / 1000 && (
                                <h3 className="slide-heading heading-8">
                                  <span className="text-span-6">
                                    {moment((getCustomCollectionTime(item.tokenContract) ?? item.timer) * 1000)
                                      .format('DD MMM yyyy, HH:mm [GMT]ZZ')
                                      .slice(0, -2)}
                                  </span>
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </div>
              </Swiper>
            )}
            <div className="swiper-navigation">
              <div className="w-embed"></div>
              <div
                ref={navigationPrevRef}
                className={
                  showNavBtn ? 'swiper-nav-btn prev w-inline-block show' : 'swiper-nav-btn prev w-inline-block'
                }
              >
                <div className="swiper-nav-btn-icon w-embed">
                  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="currentColor"
                      d="M10.3637 17.9125L6.85085 14.4183H22.7143C23.4244 14.4183 24 13.8458 24 13.1394V11.8606C24 11.1542 23.4244 10.5817 22.7143 10.5817H6.85085L10.3637 7.08752C10.8659 6.58806 10.8659 5.77832 10.3637 5.27892L9.45458 4.37459C8.95245 3.87514 8.13838 3.87514 7.63631 4.37459L0.376593 11.5957C-0.125531 12.0951 -0.125531 12.9048 0.376593 13.4043L7.63631 20.6254C8.13844 21.1249 8.95251 21.1249 9.45458 20.6254L10.3637 19.7211C10.8659 19.2217 10.8659 18.4119 10.3637 17.9125Z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                ref={navigationNextRef}
                className={
                  showNavBtn ? 'swiper-nav-btn next w-inline-block show' : 'swiper-nav-btn next w-inline-block '
                }
              >
                <div className="swiper-nav-btn-icon w-embed">
                  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="currentColor"
                      d="M13.6363 7.08752L17.1492 10.5817H1.28572C0.575625 10.5817 0 11.1542 0 11.8606V13.1394C0 13.8458 0.575625 14.4183 1.28572 14.4183H17.1492L13.6363 17.9125C13.1342 18.4119 13.1342 19.2217 13.6363 19.7211L14.5455 20.6254C15.0476 21.1249 15.8617 21.1249 16.3637 20.6254L23.6234 13.4043C24.1255 12.9048 24.1255 12.0951 23.6234 11.5957L16.3637 4.37459C15.8616 3.87514 15.0475 3.87514 14.5455 4.37459L13.6363 5.27892C13.1342 5.77832 13.1342 6.58806 13.6363 7.08752V7.08752Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-trigger-wrap">
          <div className="modal-trigger">
            <div className="nav-item-link">
              <div>
                <span>Want more?</span>
              </div>
            </div>
          </div>
        </div>
        <div className="controls">
          <div
            className="control-btn"
            onClick={() => {
              setShowNavBtn(!showNavBtn)
            }}
          >
            <div className="icon-show-slider-nav w-embed">
              <svg width="100%" height="100%" viewBox="0 0 39 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M10.3637 13.9125L6.85081 10.4183L22.7143 10.4183C23.4244 10.4183 24 9.84575 24 9.13944L24 7.86056C24 7.15425 23.4244 6.58168 22.7143 6.58168L6.85081 6.58168L10.3637 3.08752C10.8658 2.58806 10.8658 1.77832 10.3637 1.27892L9.45455 0.37459C8.95242 -0.124865 8.13835 -0.124865 7.63628 0.37459L0.376595 7.59572C-0.125531 8.09518 -0.125531 8.90492 0.376594 9.40432L7.63628 16.6254C8.1384 17.1249 8.95247 17.1249 9.45454 16.6254L10.3637 15.7211C10.8658 15.2217 10.8658 14.4119 10.3637 13.9125V13.9125Z"
                ></path>
                <path
                  fill="currentColor"
                  d="M28.6363 11.0875L32.1492 14.5817L16.2857 14.5817C15.5756 14.5817 15 15.1542 15 15.8606L15 17.1394C15 17.8458 15.5756 18.4183 16.2857 18.4183L32.1492 18.4183L28.6363 21.9125C28.1341 22.4119 28.1341 23.2217 28.6363 23.7211L29.5454 24.6254C30.0475 25.1249 30.8616 25.1249 31.3637 24.6254L38.6234 17.4043C39.1255 16.9049 39.1255 16.0952 38.6234 15.5957L31.3637 8.37459C30.8616 7.87514 30.0475 7.87514 29.5454 8.37459L28.6363 9.27892C28.1341 9.77832 28.1341 10.5881 28.6363 11.0875Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
