import { useWeb3React } from '@web3-react/core'
import Banner from 'assets/images/ApplyBanner.png'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useLoginBySignedMutation } from 'state/daos/slice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setApply } from 'state/submitartwork/slice'
import { updateUserInfo } from 'state/user/reducer'

export default function Apply({ onNextClick }: { onNextClick: () => void }) {
  const history = useHistory()
  const { account, provider } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const currentUser = useAppSelector((state) => state.user.userInfo)
  const dispatch = useAppDispatch()

  const [loginBySigned] = useLoginBySignedMutation()
  const addPopup = useAddPopup()
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <img
          src={Banner}
          loading="lazy"
          sizes="(max-width: 991px) 90vw, (max-width: 3200px) 60vw, 1920px"
          alt="Submit Banner"
          className="image-10"
        />
        <div className="text-block-14 submssiom-page">
          If you are interested in listing a project on the AW3C platform, please fill out this form for consideration.
          You will need to connect your Ethereum wallet before applying. If you are new to the AW3C platform, it is
          strongly recommended you familiarise yourself with the{' '}
          <span
            onClick={() => {
              history.push('/about')
            }}
            className="link-3"
          >
            <span className="text-span-17">FAQ</span>&nbsp;
          </span>
          page.
        </div>
        <div
          className="ok-button w-button"
          onClick={() => {
            if (!account || !provider) {
              toggleWalletModal()
            } else if (account && currentUser && account === currentUser.account) {
              dispatch(setApply(true))
              onNextClick()
            } else {
              const message = `Welcome to AW3C!\n\nClick to sign in and accept the AW3C Terms of Service: https://aw3c.xyz\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`
              provider
                .getSigner(account)
                .signMessage(message)
                .then((signature) => {
                  loginBySigned({ address: account, message, signature })
                    .then((response: any) => {
                      if (response?.data.code === 0) {
                        dispatch(updateUserInfo({ userInfo: { ...response.data.data, account } }))
                        dispatch(setApply(true))
                        onNextClick()
                      } else {
                        addPopup({ error: 'Login failed. Please retry.' })
                      }
                    })
                    .catch((err) => {
                      addPopup({ error: 'Login failed. Please retry.' })
                    })
                })
                .catch((err) => {
                  addPopup({ error: 'Login failed. Please retry.' })
                })
            }
          }}
        >
          Apply
        </div>
      </div>
    </div>
  )
}
