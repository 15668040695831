import { NON_WORD_CHAR_REGEX, SYM_MAX_LENGTH } from 'constants/club'

export const validateEmail = (email: string): boolean => {
  const regexp = /^[\w.%+-]+@[\w.-]+\.[\w]{2,}$/
  return regexp.test(email)
}

export const validateUrl = (url: string): boolean => {
  const regexp = /https?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/
  return regexp.test(url)
}

export const symbolValidation = (sym: string): Record<string, string> => {
  const errorText = {
    invalidChar: 'Only letters and numbers allowed',
    maxChar: `Up to ${SYM_MAX_LENGTH} characters allowed`
  }

  let res = {
    validSym: sym,
    errorMsg: ''
  }

  if (NON_WORD_CHAR_REGEX.test(sym)) {
    res = {
      validSym: sym.replaceAll(NON_WORD_CHAR_REGEX, ''),
      errorMsg: errorText.invalidChar
    }
  }

  if (res.validSym.length > SYM_MAX_LENGTH) {
    res = {
      validSym: res.validSym.slice(0, SYM_MAX_LENGTH),
      errorMsg: errorText.maxChar
    }
  }

  return res
}

//用word方式计算正文字数
export const fnGetCpmisWords = (str: string) => {
  let sLen = 0
  //先将回车换行符做特殊处理
  str = str.replace(/(\r\n+|\s+|　+)/g, '龘')
  //处理英文字符数字，连续字母、数字、英文符号视为一个单词
  // eslint-disable-next-line no-control-regex
  str = str.replace(/[\x00-\xff]/g, 'm')
  //合并字符m，连续字母、数字、英文符号视为一个单词
  str = str.replace(/m+/g, '*')
  //去掉回车换行符
  str = str.replace(/龘+/g, '')
  //返回字数
  sLen = str.length
  return sLen
}

export const fnGetPrefixWords = (str: string, len: number) => {
  const sorTemp = str.split('')
  let temp = str.replaceAll('\r\n', '龘')
  temp = temp.replaceAll('\t', '龘')
  temp = temp.replaceAll(' ', '龘')

  //处理英文字符数字，连续字母、数字、英文符号视为一个单词
  // eslint-disable-next-line no-control-regex
  temp = temp.replace(/[\x00-\xff]/g, 'm')
  const strArr = temp.split('')
  let subIndex = 0
  let wordLen = 1
  for (let index = 0; index < strArr.length; index++) {
    if (
      (strArr[index] !== 'm' && strArr[index] !== '龘') ||
      (strArr[index] !== 'm' && index > 0 && strArr[index - 1] === 'm')
    ) {
      if (wordLen >= len) {
        subIndex = index
        break
      }
      wordLen++
    }
  }

  console.log(subIndex, wordLen)
  if (subIndex === 0) {
    return sorTemp.join('')
  }
  return sorTemp.slice(0, subIndex).join('')
}
