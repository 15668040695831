import { useWeb3React } from '@web3-react/core'
import AIEngine from 'pages/Submit/aiengine'
import Apply from 'pages/Submit/apply'
import CollectionName from 'pages/Submit/collectionName'
import CreatorNames from 'pages/Submit/creator'
import Description from 'pages/Submit/description'
import EmailPanel from 'pages/Submit/email'
import OriginalAck from 'pages/Submit/originalAck'
import PricingAck from 'pages/Submit/pricingAck'
import SocialHandles from 'pages/Submit/socials'
import SubmitLoading from 'pages/Submit/SubmitLoading'
import SubmitSuccess from 'pages/Submit/success'
import URL from 'pages/Submit/url'
import Website from 'pages/Submit/website'
import { useCallback, useState } from 'react'
import uuid from 'react-uuid'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useLoginBySignedMutation, useSubmitArtworkMutation } from 'state/daos/slice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetSubmitArtworkReduxState } from 'state/submitartwork/slice'
import { updateUserInfo } from 'state/user/reducer'

export default function Submit() {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const currentUser = useAppSelector((state) => state.user.userInfo)
  const dispatch = useAppDispatch()
  const { account, provider } = useWeb3React()
  const [currentStep, setCurrentStep] = useState(0)
  const [submit, { isLoading }] = useSubmitArtworkMutation()
  const [loginBySigned] = useLoginBySignedMutation()
  const toggleWalletModal = useWalletModalToggle()
  const addPopup = useAddPopup()

  const handleStepNext = useCallback(() => {
    setCurrentStep(currentStep + 1)
  }, [currentStep])

  const handleStepBack = useCallback(() => {
    setCurrentStep(currentStep - 1)
  }, [currentStep])

  const handleStepConfirm = useCallback(() => {
    if (!account || !provider) {
      toggleWalletModal()
    } else if (account && currentUser && account === currentUser.account) {
      submit({
        creator: submitArtwork.creatorNames,
        creatorAddress: account,
        email: submitArtwork.email,
        collectionName: submitArtwork.collectionName,
        description: submitArtwork.description,
        engine: submitArtwork.engine,
        website: submitArtwork.website,
        socialLink: submitArtwork.socialHandle,
        compressedUrl: submitArtwork.artUri
      })
        .then((response: any) => {
          if (response?.data?.code === 0) {
            dispatch(resetSubmitArtworkReduxState())
            handleStepNext()
          } else {
            addPopup({ error: 'Submit artwork failed. Please retry' })
          }
        })
        .catch((error) => {
          addPopup({ error: 'Submit artwork failed. Please retry' })
        })
    } else {
      const message = `Welcome to AW3C!\n\nClick to sign in and accept the AW3C Terms of Service: https://aw3c.xyz\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`
      provider
        .getSigner(account)
        .signMessage(message)
        .then((signature) => {
          loginBySigned({ address: account, message, signature })
            .then((response: any) => {
              if (response?.data.code === 0) {
                dispatch(updateUserInfo({ userInfo: { ...response.data.data, account } }))
                submit({
                  creator: submitArtwork.creatorNames,
                  creatorAddress: account,
                  email: submitArtwork.email,
                  collectionName: submitArtwork.collectionName,
                  description: submitArtwork.description,
                  engine: submitArtwork.engine,
                  website: submitArtwork.website,
                  socialLink: submitArtwork.socialHandle,
                  compressedUrl: submitArtwork.artUri
                })
                  .then((response: any) => {
                    if (response?.data?.code === 0) {
                      dispatch(resetSubmitArtworkReduxState())
                      handleStepNext()
                    } else {
                      addPopup({ error: 'Submit artwork failed. Please retry' })
                    }
                  })
                  .catch((error) => {
                    addPopup({ error: 'Submit artwork failed. Please retry' })
                  })
              } else {
                addPopup({ error: 'Login failed. Please retry.' })
              }
            })
            .catch((err) => {
              addPopup({ error: 'Login failed. Please retry.' })
            })
        })
        .catch((err) => {
          addPopup({ error: 'Login failed. Please retry.' })
        })
    }
  }, [
    account,
    addPopup,
    currentUser,
    dispatch,
    handleStepNext,
    loginBySigned,
    provider,
    submit,
    submitArtwork.artUri,
    submitArtwork.collectionName,
    submitArtwork.creatorNames,
    submitArtwork.description,
    submitArtwork.email,
    submitArtwork.engine,
    submitArtwork.socialHandle,
    submitArtwork.website,
    toggleWalletModal
  ])

  return (
    <>
      <div className="section-6 wf-section">
        <div className="div-block-6">
          <h1 className="heading-6">Submit artwork</h1>
        </div>
      </div>
      {currentStep === 0 && <Apply onNextClick={handleStepNext}></Apply>}
      {currentStep === 1 && <CreatorNames onNextClick={handleStepNext}></CreatorNames>}
      {currentStep === 2 && <EmailPanel onNextClick={handleStepNext} onBackClick={handleStepBack}></EmailPanel>}
      {currentStep === 3 && <CollectionName onNextClick={handleStepNext} onBackClick={handleStepBack}></CollectionName>}
      {currentStep === 4 && <Description onNextClick={handleStepNext} onBackClick={handleStepBack}></Description>}
      {currentStep === 5 && <AIEngine onNextClick={handleStepNext} onBackClick={handleStepBack}></AIEngine>}
      {currentStep === 6 && <Website onNextClick={handleStepNext} onBackClick={handleStepBack}></Website>}
      {currentStep === 7 && <SocialHandles onNextClick={handleStepNext} onBackClick={handleStepBack}></SocialHandles>}
      {currentStep === 8 && <URL onNextClick={handleStepNext} onBackClick={handleStepBack}></URL>}
      {currentStep === 9 && <PricingAck onNextClick={handleStepNext} onBackClick={handleStepBack}></PricingAck>}
      {currentStep === 10 && <OriginalAck onNextClick={handleStepConfirm} onBackClick={handleStepBack}></OriginalAck>}
      {currentStep === 11 && <SubmitSuccess onNextClick={() => setCurrentStep(0)}></SubmitSuccess>}
      <SubmitLoading
        isOpen={isLoading}
        onDismiss={() => {
          console.log('')
        }}
        text={'Submitting...'}
      ></SubmitLoading>
    </>
  )
}
