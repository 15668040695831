import * as S from 'pages/Submit/styles'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setEmail } from 'state/submitartwork/slice'
import { validateEmail } from 'utils/validators'
export default function EmailPanel({ onNextClick, onBackClick }: { onNextClick: () => void; onBackClick: () => void }) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  const [error, setError] = useState('')
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className="div-block-11">
          <div className="text-block-14">
            2. Email Address* <span className="text-span-13">- </span>
            <span className="text-span-12">Initial communications will be sent to this email address.</span>‍<br />
          </div>
          <S.Input
            value={submitArtwork.email}
            type="email"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            autoFocus
            onChange={(event) => {
              const input = event.target.value
              if (error) {
                setError('')
              }
              dispatch(setEmail(input))
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (validateEmail(submitArtwork.email)) {
                  onNextClick()
                } else {
                  setError('Please input correct email')
                }
              }
            }}
          />
        </div>

        {error && <S.ErrorPanel>{error}</S.ErrorPanel>}
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div
          onClick={() => {
            if (validateEmail(submitArtwork.email)) {
              onNextClick()
            } else {
              setError('Please input correct email')
            }
          }}
          className="ok-button w-button"
        >
          OK <strong className="bold-text">↵</strong>
        </div>
      </div>
    </div>
  )
}
