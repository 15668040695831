function underline2Hump(s: string) {
  return s.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
  })
}
export function jsonToHump(obj: any) {
  if (obj instanceof Array) {
    obj.forEach(function (v, i) {
      jsonToHump(v)
    })
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      const newKey = underline2Hump(key)
      if (newKey !== key) {
        obj[newKey] = obj[key]
        delete obj[key]
      }
      jsonToHump(obj[newKey])
    })
  }
}
