import useENSName from 'hooks/useENSName'
import CreatedPanel from 'pages/Profile/CreatedPanel'
import OwnTokensPanel from 'pages/Profile/OwnTokensPanel'
import * as S from 'pages/Profile/styles'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAccountProjectsQuery } from 'state/data/generated'
import { shortAddress } from 'utils/safeNamehash'

export default function Profile({
  match: {
    params: { address }
  }
}: RouteComponentProps<{ address: string }>) {
  const [tab, setTab] = useState(0)
  const { ENSName } = useENSName(address)
  const [artistName, setArtistName] = useState(null)
  const { data } = useAccountProjectsQuery({
    account: address.toLowerCase(),
    first: 1,
    skip: 0
  })

  useEffect(() => {
    if (data) {
      if (data.accountProjects && data.accountProjects.length > 0 && data.accountProjects[0].project) {
        setArtistName(data.accountProjects[0].project.artistName)
      }
    }
  }, [data])

  return (
    <S.Panel>
      <div className="section-6 wf-section">
        <div className="div-block-6">
          <div className="columns-7 w-row">
            <div className="w-col w-col-8">
              <h1 className="heading-6">{artistName ?? ENSName ?? shortAddress(address, 6, 4)}</h1>
            </div>
            <div className="w-col w-col-2">
              <div
                onClick={() => setTab(0)}
                className={tab === 0 ? 'text-block-9 all detail-filter-active' : 'text-block-9 all '}
              >
                Collected<span className="text-span-8"></span>
              </div>
            </div>
            <div className="w-col w-col-2">
              <div
                onClick={() => setTab(1)}
                className={tab === 1 ? 'text-block-9 all detail-filter-active' : 'text-block-9 all '}
              >
                Created<span className="text-span-9"></span>
              </div>
            </div>
          </div>

          {tab === 0 && <OwnTokensPanel address={address}></OwnTokensPanel>}
          {tab === 1 && <CreatedPanel address={address}></CreatedPanel>}
        </div>
      </div>
    </S.Panel>
  )
}
