import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'

export const StyledExtLink = styled(ExternalLink)`
  text-decoration: none;
  cursor: pointer;
  color: #c2c2c2;

  :hover {
    text-decoration: underline;
    color: #c2c2c2;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

export const StyledInLink = styled.em`
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`
