// eslint-disable-next-line no-restricted-imports
import 'styles/normalize.css'
import 'styles/webflow.css'
import 'styles/custom-design.css'
import 'styles/darcula-vibrand-default.css'
import 'styles/darcula-vibrand.css'
import 'styles/grain-noise.css'
import 'styles/nav.css'

import { useAnalyticsReporter } from 'components/Analytics'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Loader from 'components/Loader'
import Popups from 'components/Popups'
import TopLevelModals from 'components/TopLevelModals'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import About from 'pages/About'
import Collection from 'pages/Collection'
import Detail from 'pages/Detail'
import Home from 'pages/Home'
import { RedirectPathToHomeOnly } from 'pages/Home/redirects'
import Profile from 'pages/Profile'
import Submit from 'pages/Submit'
import { Suspense } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'

export default function App() {
  const history = useHistory()
  useAnalyticsReporter(useLocation())

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />

      <div className="page-content">
        <div
          data-animation="over-right"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <Header />
        </div>
        <div>
          <Popups />
          <TopLevelModals />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact strict path="/" component={Home} />
              <Route exact strict path="/about" component={About} />
              <Route exact strict path="/detail/:projectId" component={Detail} />
              <Route exact strict path="/detail/:projectId/:tokenId" component={Detail} />
              <Route exact strict path="/collection" component={Collection} />
              <Route exact strict path="/submit" component={Submit} />
              <Route exact strict path="/profile/:address" component={Profile} />

              <Route component={RedirectPathToHomeOnly} />
            </Switch>
          </Suspense>
        </div>
        <div className="footer wf-section">
          <Footer></Footer>
        </div>
      </div>
    </ErrorBoundary>
  )
}
