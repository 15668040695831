import Banner from 'assets/images/aboutbanner.png'
import AboutContent from 'pages/About/AboutContent'
import * as S from 'pages/About/styles'
import { useHistory } from 'react-router-dom'

export default function About() {
  const history = useHistory()
  return (
    <>
      <div className="section-5 wf-section">
        <div className="w-container">
          <h1 className="heading-5">
            AW3C is leading ‘the renaissance of art’ to a generational change in art perception, consumption and
            distribution.
          </h1>
        </div>
      </div>
      <div className="section-4 wf-section">
        <img
          src={Banner}
          loading="lazy"
          // srcSet="images/8-p-500.png 500w, images/8-p-800.png 800w, images/8-p-1080.png 1080w, images/8-p-1600.png 1600w, images/8-p-2000.png 2000w, images/8.png 2560w"
          sizes="(max-width: 2560px) 100vw, 2560px"
          alt=""
        />
      </div>
      <AboutContent title={'What is AW3C'} isTop={true}>
        <em className="italic-text-2">
          AW3C is a first-of-its-kind platform focused on AI-generated art that is stored immutably on the Ethereum
          Blockchain. It represents fundamental innovation in the combination of art and technology.
        </em>
      </AboutContent>
      <AboutContent title={'About AW3C platform'}>
        <em className="italic-text-2">
          The AW3C platform offers artists and collectors the ability to trade AI-generated art as NFTs in a secure and
          transparent way on the Ethereum blockchain, while ensures the authenticated artworks are permanently stored
          and copyright is fully protected
        </em>
      </AboutContent>
      <AboutContent title={'Why Blockchain'}>
        <em className="italic-text-2">
          For centuries, artists and collectors have struggled with various issues such as authenticity, logistics,
          storage, theft, high intermediary fees, and poor liquidity. AW3C uses blockchain technology to create AI
          generated art as NFTs (non-fungible tokens) stored immutably on the blockchain. The authenticity of the art is
          guaranteed. Collectors are able to buy and sell art anytime, anywhere around the world without the trouble of
          logistics, intermediary fees, and insurance. Delivery is almost instant.
        </em>
      </AboutContent>
      <AboutContent title={'Why NFT'}>
        <em className="italic-text-2">
          In the past, there were major issues that prevented the golden age of gen-art. They were technical, aesthetic,
          and financial. Today, all those limitations have disappeared, and it is now possible to create, store, and
          distribute generative art in a very efficient way by utilizing blockchain technology. It is the best way to
          value artwork and give credit to an artist. AW3C uses ERC721 standard to tokenise generative art, and enable
          it to be stored, transferred and traded on the blockchain.
        </em>
      </AboutContent>
      <AboutContent title={'What is a curated collection?'}>
        <em className="italic-text-2">
          AW3C established a curation board to carefully select projects for inclusion in our Curated Collection. The
          Curated Collection is a group of projects that push the boundaries of AI-generated art in their technical
          innovation and aesthetic beauty. The Curated Collection best represents AW3C’s vision as a platform and is
          released on a regular schedule. Each of the curated collections consist of 20 to 1000 artworks from the
          world’s best creative creators.
        </em>
      </AboutContent>
      <AboutContent title={'How do I apply to be on AW3C?'}>
        <em>You may apply </em>
        <strong>
          <S.StyledInLink
            onClick={() => {
              history.push('/submit')
            }}
          >
            here
          </S.StyledInLink>
        </strong>
        <em>
          . We expect that artists/creators have a creative history as well as the ability to provide an original
          generative artwork. AW3C does not provide assistance with the generation of projects. All submitted work must
          be original. We do not intend to release projects that use licensed, purchased or recycled content. Please
          make sure you’re bringing your own unique project to AW3C.
        </em>
      </AboutContent>
      <AboutContent title={'What happens after I apply?'}>
        <em>
          After you submit your artwork, our creative team will begin the process of reviewing applications in the order
          they were received. Once your collection is reviewed by our creative board, you will be notified of the
          outcome by email. If your collection is selected to be launched on the platform, it can also be viewed under
          your profile page - created.
        </em>
      </AboutContent>
      <AboutContent title={'How does curation work?'}>
        <em>
          Artists/creators are selected to deploy projects at the sole discretion of the AW3C team. We reserve the right
          to curate the content on the platform without explanation. That said, no content which could be considered
          even remotely offensive to anyone will be considered.
        </em>
      </AboutContent>
      <AboutContent title={'What is the expected timeline for application?'}>
        <em>
          Once you submit your collection, it will take approximately 1-2 weeks to review and test it on the AW3C
          platform. You can reach out to our Artist Liaison on discord or send an email to{' '}
          <S.StyledExtLink href="mailto:support@aw3c.xyz">support@aw3c.xyz</S.StyledExtLink> for status updates.
        </em>
      </AboutContent>
      <AboutContent title={'How is the price for a collection determined?'}>
        <em>
          Due to the complexity of the NFT and crypto markets, and for the best interest of the creator and the AW3C
          platform, the price of a collection is set at the discretion of the AW3C team.
        </em>
      </AboutContent>
      <AboutContent title={'Are there platform fees?'}>
        <em>
          It is free for creators to submit and list their projects on the AW3C platform. The AW3C platform takes 10% of
          the primary sales and 2.5% of secondary sales. Balance of each sale will be deposited to the artist/creator
          immediately.
        </em>
      </AboutContent>
      <AboutContent title={'How does creator secondary royalty works?'}>
        <em>For all secondary sales on the market, creator always receive 5% of the purchase price.</em>
      </AboutContent>
      <AboutContent title={'How does the payout work?'}>
        <em>
          At each sale (primary and secondary) of your collection, your creator&#x27;s percentage payment will be
          transferred immediately to your wallet address that you use to submit your artwork. All payouts will be in the
          form of ETH.
        </em>
      </AboutContent>
      <AboutContent title={'How does copyright works?'}>
        <em>
          Currently, both U.S and U.K officials have rejected copyright protection for Artificial Intelligence generated
          images, claiming &#x27;image generated through artificial intelligence lacked the &quot;human authorship&quot;
          necessary for protection&#x27;. But in other countries like Australia, South Africa, inventors of AI generated
          work can apply for a patent. Nevertheless, the best way to protect your artwork is through blockchain
          technology where the ownership record is stored immutably on the blockchain and can be verified by the public.
        </em>
      </AboutContent>

      <AboutContent title={'What is a project license, and which rights does it grant to me?'}>
        <em>
          Currently, all projects on the AW3C platform are covered under the{' '}
          <S.StyledExtLink href="https://www.niftylicense.org/">
            <em className="italic-text-3">NFT License 2.0</em>
          </S.StyledExtLink>
          . <br />
          Purchasing artwork on AW3C does not give collectors intellectual property rights to the underlying AI
          algorithm that generates the content. If you have any questions about the appropriate use of an art piece,
          please contact the artist/creator directly for permission or clarification.
        </em>
      </AboutContent>
      <AboutContent title={'How do I get a wallet?'}>
        <em>You will need a wallet to sell artwork and receive funds. There are several options to choose from. </em>
        <S.StyledExtLink href="https://ethereum.org/en/wallets/">
          <em className="italic-text-3">https://ethereum.org/en/wallets/</em>
        </S.StyledExtLink>
      </AboutContent>
      <AboutContent title={'How do I purchase/mint an artwork NFT?'}>
        <em>
          Connect your Ethereum wallet and choose an art work you like. Simply click ‘Mint’, and follow the steps of
          your wallet to confirm the transaction, then it will be minted as an ERC721 token on the Ethereum blockchain.
        </em>
      </AboutContent>
      <AboutContent title={'What if my questions isn’t answered here?'}>
        <em>
          If you can&#x27;t find the answer here, please join our Discord server. In Discord, you can find our thriving
          community of talented artists and collectors. You can also join daily conversations about AI generated art and
          upcoming projects. Additionally, Discord is the best place to ask us for help. You will find information about
          our artist application process, user feedback &amp; announcements for all things AW3C. We&#x27;d love to have
          you there! <br />
          <br />
          Click <S.StyledExtLink href="https://discord.gg/aw3c">here</S.StyledExtLink> to join!
        </em>
      </AboutContent>
    </>
  )
}
