import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setAcceptPriceAck } from 'state/submitartwork/slice'

export default function PricingAck({ onNextClick, onBackClick }: { onNextClick: () => void; onBackClick: () => void }) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (submitArtwork.acceptPriceAck) {
      onNextClick()
    }
  }, [onNextClick, submitArtwork.acceptPriceAck])
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div>
          <div className="text-block-14">
            9. Selection and pricing Acknowledgement{' '}
            <span className="text-span-14">
              - I acknowledge that AW3C has full discretion as to the selection and pricing strategy of projects
              deployed on the platform.
            </span>
            <br />‍<br />
          </div>
        </div>
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div
          onClick={() => {
            dispatch(setAcceptPriceAck(true))
            onNextClick()
          }}
          className="ok-button w-button"
        >
          Accept
        </div>
      </div>
    </div>
  )
}
