import { BigNumberish } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useControllerContract } from 'hooks/useContract'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export function useSafeMintCallback(controllerContract: string): {
  mintCallback: (to: string, tokenId: string, price: BigNumberish) => undefined | Promise<string>
} {
  const { account, chainId } = useWeb3React()
  const addTransaction = useTransactionAdder()
  const contract = useControllerContract(controllerContract, true)

  const mintCallback = useCallback(
    (to: string, tokenId: string, price: BigNumberish) => {
      if (!chainId || !account) return undefined
      if (!contract) throw new Error('No Factory Contract!')
      return contract.estimateGas.mint(to, tokenId, { value: price }).then((estimatedGasLimit) => {
        return contract
          .mint(to, tokenId, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            value: price
          })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              type: TransactionType.SAFE_MINT,
              to
            })
            return response.hash
          })
      })
    },
    [chainId, account, contract, addTransaction]
  )
  return { mintCallback }
}
