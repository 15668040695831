import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import { DocumentNode } from 'graphql'
import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { AppState } from 'state'

// List of supported subgraphs. Note that the app currently only support one active subgraph at a time
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/yyd01245/aw3c-mainnet'
  // [SupportedChainId.RINKEBY]: 'https://api.thegraph.com/subgraphs/name/yyd01245/aw3c',
  // [SupportedChainId.LOCAL_HOST]: 'https://api.thegraph.com/subgraphs/name/yyd01245/aw3c'
}

export const api = createApi({
  reducerPath: 'dataApi',
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: (builder) => ({
    allProjects: builder.query({
      query: ({ first, skip = 0 }) => ({
        document: gql`
          query allProjects($first: Int!, $skip: Int!) {
            projects(first: $first, skip: $skip, orderBy: createdAt, orderDirection: desc) {
              artistName
              baseUri
              collectionName
              complete
              controllerContract
              createdAt
              creatorFeeAddress
              creator(first: 10) {
                id
              }
              description
              engine
              id
              license
              mPaused
              mPercentage
              maxSupply
              name
              platformFeeAddress
              price
              projectContract
              tPaused
              timer
              tokenContract
              tokens(first: 1) {
                createdAt
                contract
                id
                supply
                tokenId
                transactionHash
                updatedAt
                uri
                owner {
                  id
                }
              }
              totalSupply
              updatedAt
            }
          }
        `,
        variables: {
          first,
          skip
        }
      })
    }),
    projectDetail: builder.query({
      query: ({ id }) => ({
        document: gql`
          query projectDetail($id: ID!) {
            project(id: $id) {
              artistName
              baseUri
              collectionName
              complete
              controllerContract
              createdAt
              creatorFeeAddress
              creator(first: 10) {
                account {
                  id
                }
                id
              }
              description
              engine
              id
              license
              mPaused
              mPercentage
              maxSupply
              name
              platformFeeAddress
              price
              projectContract
              tPaused
              timer
              tokenContract
              tokens {
                createdAt
                contract
                id
                supply
                tokenId
                transactionHash
                updatedAt
                uri
                owner {
                  id
                }
              }
              totalSupply
              updatedAt
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    accountProjects: builder.query({
      query: ({ account, first, skip = 0 }) => ({
        document: gql`
          query accountProjects($account: String!, $first: Int!, $skip: Int!) {
            accountProjects(first: $first, skip: $skip, where: { account: $account }) {
              account {
                id
              }
              project {
                artistName
                baseUri
                collectionName
                complete
                controllerContract
                createdAt
                creatorFeeAddress
                creator(first: 10) {
                  id
                }
                description
                engine
                id
                license
                mPaused
                mPercentage
                maxSupply
                name
                platformFeeAddress
                price
                projectContract
                tPaused
                timer
                tokenContract
                tokens(first: 1) {
                  createdAt
                  contract
                  id
                  supply
                  tokenId
                  transactionHash
                  updatedAt
                  uri
                  owner {
                    id
                  }
                }
                totalSupply
                updatedAt
              }
            }
          }
        `,
        variables: {
          account,
          first,
          skip
        }
      })
    }),
    allTokens: builder.query({
      query: ({ account, first, skip = 0 }) => ({
        document: gql`
          query allTokens($account: String!, $first: Int!, $skip: Int!) {
            tokens(first: $first, skip: $skip, orderBy: createdAt, orderDirection: desc, where: { owner: $account }) {
              id
              tokenId
              contract
              uri
              project {
                artistName
                baseUri
                collectionName
                complete
                controllerContract
                createdAt
                creatorFeeAddress
                creator(first: 10) {
                  id
                }
                description
                engine
                id
                license
                mPaused
                mPercentage
                maxSupply
                name
                platformFeeAddress
                price
                projectContract
                tPaused
                timer
                tokenContract
                tokens(first: 1) {
                  createdAt
                  contract
                  id
                  supply
                  tokenId
                  transactionHash
                  updatedAt
                  uri
                  owner {
                    id
                  }
                }
                totalSupply
                updatedAt
              }
            }
          }
        `,
        variables: {
          account,
          first,
          skip
        }
      })
    })
  })
})

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> {
  return async ({ document, variables }, { getState }) => {
    try {
      console.log('state', getState())
      const chainId = (getState() as AppState).application.chainId
        ? (getState() as AppState).application.chainId
        : SupportedChainId.MAINNET

      const subgraphUrl = chainId
        ? CHAIN_SUBGRAPH_URL[chainId]
        : 'https://api.thegraph.com/subgraphs/name/yyd01245/aw3c-mainnet'

      if (!subgraphUrl) {
        return {
          error: {
            name: 'UnsupportedChainId',
            message: `Subgraph queries against ChainId ${chainId} are not supported.`,
            stack: ''
          }
        }
      }

      return { data: await new GraphQLClient(subgraphUrl).request(document, variables), meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}
