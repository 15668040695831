import CollectionCard from 'components/CollectionCard'
import { getCustomCollectionTime } from 'constants/collections'
import { useWindowSize } from 'hooks/useWindowSize'
import * as S from 'pages/Collection/styles'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-responsive-masonry'
import { useHistory } from 'react-router-dom'
import { useAllProjectsQuery } from 'state/data/generated'
import { MEDIA_WIDTHS } from 'theme'

export default function Collection() {
  const pageSize = 24
  const { width } = useWindowSize()
  const [skip, setSkip] = useState(0)
  const history = useHistory()
  const [hasMore, setHasMore] = useState(true)
  const [projects, setProjects] = useState([] as any[])
  const { data, isFetching } = useAllProjectsQuery({
    first: pageSize,
    skip
  })

  useEffect(() => {
    if (data && projects) {
      if (data.projects && (data.projects.length === 0 || data.projects.length < pageSize)) setHasMore(false)
      setProjects(projects.concat(data.projects))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageSize])
  return (
    <S.Panel>
      <div className="section-6 wf-section">
        <div className="div-block-6">
          <h1 className="heading-6">Curated Collection</h1>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            threshold={250}
            loadMore={() => {
              if (!isFetching) setSkip(skip + pageSize)
            }}
            hasMore={hasMore}
            useWindow={true}
            className="collection wf-section"
            loader={
              <div className="container-10 w-container" key={2}>
                <div>{/* <div className="text-block-12">Load more...</div> */}</div>
              </div>
            }
          >
            <Masonry columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3} gutter="20px" key={1}>
              {projects &&
                projects.map((item: any, index: any) => {
                  return (
                    <CollectionCard
                      key={item.id}
                      image={item.baseUri + item.tokens[0].tokenId}
                      name={item.name}
                      artis={item.artistName}
                      onClick={() => {
                        history.push(`/detail/${item.id}`)
                      }}
                      price={item.price}
                      minted={item.totalSupply}
                      total={item.maxSupply}
                      mintTime={getCustomCollectionTime(item.tokenContract) ?? item.timer}
                      tokenContract={item.tokenContract}
                    ></CollectionCard>
                  )
                })}
            </Masonry>
          </InfiniteScroll>
        </div>
      </div>
    </S.Panel>
  )
}
