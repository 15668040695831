import fecthData from 'lib/hooks/useFetchData'
import { useCallback } from 'react'

export function useFetchTokenUriDataCallback(): (uri: string) => Promise<any> {
  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(async (dataUrl: string) => {
    return fecthData(dataUrl, false)
      .then((tokenData) => {
        return tokenData
      })
      .catch((error) => {
        console.debug(`Failed to get list at url ${dataUrl}`, error)
        throw error
      })
  }, [])
}

export function useFetchUriDataCallback(): (uri: string) => Promise<any> {
  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(async (dataUrl: string) => {
    return fecthData(dataUrl, true)
      .then((tokenData) => {
        return tokenData
      })
      .catch((error) => {
        console.debug(`Failed to get list at url ${dataUrl}`, error)
        throw error
      })
  }, [])
}
