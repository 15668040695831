// import { BigNumberish } from '@ethersproject/bignumber'
import { animated, useSpring } from '@react-spring/web'
import DefaultImage from 'assets/images/default-image.png'
import { formatEther } from 'ethers/lib/utils'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import moment from 'moment'
// import { parseEther } from 'ethers/lib/utils'
import { useEffect, useRef, useState } from 'react'

export default function CollectionCard({
  image,
  price,
  name,
  artis,
  minted,
  total,
  mintTime,
  tokenContract,
  onClick
}: {
  image: string
  price: string
  name: string
  artis: string
  minted: number
  total: number
  mintTime: number
  tokenContract: string
  onClick?: () => void
}) {
  const [isHovering, setIsHovering] = useState(false)
  const Img = useRef<HTMLImageElement>()
  const { scale, opacity } = useSpring({
    from: { scale: 1, opacity: 1 },
    scale: isHovering ? 0.95 : 1,
    opacity: isHovering ? 0.12 : 1,
    config: { duration: 200 }
  })

  const { opacity: titleOpacity } = useSpring({
    from: { opacity: 1 },
    opacity: isHovering ? 1 : 0,
    config: { duration: 200 }
  })

  const fetchData = useFetchTokenUriDataCallback()
  const [data, setData] = useState(null as any)
  useEffect(() => {
    fetchData(image)
      .then((data) => {
        setData(data)
      })
      .catch((error) => console.debug('fetching data error', error))
  }, [fetchData, image])

  return (
    <div
      className="div-block-2"
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onClick={onClick}
    >
      <animated.img
        ref={Img as any}
        style={{ scale, opacity }}
        src={data?.external_url ?? data?.image ?? DefaultImage}
        loading="lazy"
        alt=""
        className="image-5"
        onError={() => {
          if (Img.current) {
            Img.current.src = DefaultImage
          }
        }}
      />
      <animated.div
        className="text-wrapper"
        style={{
          opacity: titleOpacity
        }}
      >
        <div className="text-block-7">
          <span className="text-span-5">
            {name}
            <br />
          </span>
          <span className="text-span-6">
            {artis}
            <br />
            {mintTime > new Date().getTime() / 1000 &&
              moment(mintTime * 1000)
                .format('DD MMM yyyy, HH:mm [GMT]ZZ')
                .slice(0, -2)}
            <br />‍
          </span>
          <br />
          {minted} of {total}   Price {formatEther(price)} Ξ <br />
        </div>
      </animated.div>
    </div>
  )
}
