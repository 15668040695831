import CollectionCard from 'components/CollectionCard'
import { getCustomCollectionTime } from 'constants/collections'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-responsive-masonry'
import { useHistory } from 'react-router-dom'
import { useAccountProjectsQuery } from 'state/data/generated'
import { MEDIA_WIDTHS } from 'theme'

export default function CreatedPanel({ address }: { address: string }) {
  const pageSize = 9
  const history = useHistory()
  const { width } = useWindowSize()
  const [skip, setSkip] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [projects, setProjects] = useState([] as any[])
  const { data, isFetching } = useAccountProjectsQuery({
    account: address.toLowerCase(),
    first: pageSize,
    skip
  })

  useEffect(() => {
    if (data && projects) {
      if (data.accountProjects && (data.accountProjects.length === 0 || data.accountProjects.length < pageSize))
        setHasMore(false)
      setProjects(projects.concat(data.accountProjects))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <div className="collection wf-section">
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        threshold={250}
        loadMore={() => {
          if (!isFetching) setSkip(skip + pageSize)
        }}
        hasMore={hasMore}
        useWindow={true}
        loader={
          <div className="container-10 w-container" key={2}>
            <div>{/* <div className="text-block-12">Load more...</div> */}</div>
          </div>
        }
      >
        <Masonry
          columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3}
          gutter="20px"
          className="collection wf-section"
          key={1}
        >
          {projects &&
            projects.map((item: any, index: any) => {
              return (
                <CollectionCard
                  key={item.project.id}
                  image={item.project.baseUri + item.project.tokens[0].tokenId}
                  name={item.project.name}
                  artis={item.project.artistName}
                  onClick={() => {
                    history.push(`/detail/${item.project.id}`)
                  }}
                  price={item.project.price}
                  minted={item.project.totalSupply}
                  total={item.project.maxSupply}
                  mintTime={getCustomCollectionTime(item.project.tokenContract) ?? item.project.timer}
                  tokenContract={item.project.tokenContract}
                ></CollectionCard>
              )
            })}
        </Masonry>
      </InfiniteScroll>
    </div>
  )
}
