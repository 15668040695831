import { animated, useSpring } from '@react-spring/web'
import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

const Content = styled(animated.div)`
  will-change: transform, height;
  overflow: hidden;
`

export default function AboutContent({
  title,
  isTop,
  children
}: {
  title: string
  isTop?: boolean
  children?: ReactNode
}) {
  const [showContent, setShowContent] = useState(false)
  const { height } = useSpring({
    from: { height: 0 },
    height: showContent ? 'fit-content' : 0,
    config: { duration: 1000 }
  })
  return (
    <div className={isTop ? 'about page _1 top wf-section' : 'about page _1 wf-section'}>
      <div className="w-container">
        <h2
          className="heading-4"
          onClick={() => {
            setShowContent(!showContent)
          }}
        >
          <strong className="bold-text-2">{title}</strong>
        </h2>
        <Content className="text-block-6" style={{ height }}>
          {children}
        </Content>
      </div>
    </div>
  )
}
