import styled from 'styled-components/macro'

export const Grid = styled.div`
  column-count: 3; /*css3新增，把contaner容器中的内容分为三列*/
  column-gap: 20px; /*定义列之间的间隙为20px*/
  height: auto;
`

export const Panel = styled.div`
  min-height: 80vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: 50vh;
  `};
`
