import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'

export const Grid = styled.div`
  column-count: 3; /*css3新增，把contaner容器中的内容分为三列*/
  column-gap: 20px; /*定义列之间的间隙为20px*/
  height: auto;
`

export const Hero = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  over-flow: hidden;
`

export const Video = styled.div`
  background: #ffffff;
  img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
  }
`

export const Info = styled.div<{ len: number; width: number }>`
  width: fit-content;
  word-break: break-word;
`

export const Container = styled.div`
  width: 50vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  over-flow: hidden;
  background-color: #222;
  border-radius: 40px;
  border: 1px solid #ededed;
`

export const StyledExtLink = styled(ExternalLink)`
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  width: 50%;
  text-align: center;

  :hover {
    text-decoration: underline;
    color: #fff;
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }
`

export const StyledInLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  width: 50%;
  text-align: center;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

export const Content = styled.div`
  font-weight: 600;
  font-size: 2.5vw;
`

export const InfoContainer = styled.div`
  width: fit-content;
  min-width: 50%;
`
export const PriceContainer = styled.div`
  width: fit-content;
  min-width: 25%;
`
export const MintContainer = styled.div`
  width: fit-content;
  flex: 1;
  justify-content: flex-end;
  display: flex;
`
export const OwnerContainer = styled.div`
  width: fit-content;
  min-width: 33.33333333%;
`
