import { api } from './slice';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};




export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  tokens?: Maybe<Array<Token>>;
  /** Projects the account owns tokens from */
  projectsOwned?: Maybe<Array<AccountProject>>;
};


export type AccountTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
};


export type AccountProjectsOwnedArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AccountProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<AccountProject_Filter>;
};

export type AccountProject = {
  __typename?: 'AccountProject';
  id: Scalars['ID'];
  account: Account;
  project: Project;
};

export type AccountProject_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_contains_nocase?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_not_contains_nocase?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_starts_with_nocase?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with_nocase?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_ends_with_nocase?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with_nocase?: Maybe<Scalars['String']>;
  account_?: Maybe<Account_Filter>;
  project?: Maybe<Scalars['String']>;
  project_not?: Maybe<Scalars['String']>;
  project_gt?: Maybe<Scalars['String']>;
  project_lt?: Maybe<Scalars['String']>;
  project_gte?: Maybe<Scalars['String']>;
  project_lte?: Maybe<Scalars['String']>;
  project_in?: Maybe<Array<Scalars['String']>>;
  project_not_in?: Maybe<Array<Scalars['String']>>;
  project_contains?: Maybe<Scalars['String']>;
  project_contains_nocase?: Maybe<Scalars['String']>;
  project_not_contains?: Maybe<Scalars['String']>;
  project_not_contains_nocase?: Maybe<Scalars['String']>;
  project_starts_with?: Maybe<Scalars['String']>;
  project_starts_with_nocase?: Maybe<Scalars['String']>;
  project_not_starts_with?: Maybe<Scalars['String']>;
  project_not_starts_with_nocase?: Maybe<Scalars['String']>;
  project_ends_with?: Maybe<Scalars['String']>;
  project_ends_with_nocase?: Maybe<Scalars['String']>;
  project_not_ends_with?: Maybe<Scalars['String']>;
  project_not_ends_with_nocase?: Maybe<Scalars['String']>;
  project_?: Maybe<Project_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum AccountProject_OrderBy {
  Id = 'id',
  Account = 'account',
  Project = 'project'
}

export type Account_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  tokens_?: Maybe<Token_Filter>;
  projectsOwned_?: Maybe<AccountProject_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum Account_OrderBy {
  Id = 'id',
  Tokens = 'tokens',
  ProjectsOwned = 'projectsOwned'
}



export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
};

export type Builded = {
  __typename?: 'Builded';
  id: Scalars['ID'];
  index: Scalars['Bytes'];
  builder: Scalars['Bytes'];
  controllerproxy: Scalars['Bytes'];
  aerc721proxy: Scalars['Bytes'];
};

export type Builded_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  index?: Maybe<Scalars['Bytes']>;
  index_not?: Maybe<Scalars['Bytes']>;
  index_in?: Maybe<Array<Scalars['Bytes']>>;
  index_not_in?: Maybe<Array<Scalars['Bytes']>>;
  index_contains?: Maybe<Scalars['Bytes']>;
  index_not_contains?: Maybe<Scalars['Bytes']>;
  builder?: Maybe<Scalars['Bytes']>;
  builder_not?: Maybe<Scalars['Bytes']>;
  builder_in?: Maybe<Array<Scalars['Bytes']>>;
  builder_not_in?: Maybe<Array<Scalars['Bytes']>>;
  builder_contains?: Maybe<Scalars['Bytes']>;
  builder_not_contains?: Maybe<Scalars['Bytes']>;
  controllerproxy?: Maybe<Scalars['Bytes']>;
  controllerproxy_not?: Maybe<Scalars['Bytes']>;
  controllerproxy_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerproxy_not_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerproxy_contains?: Maybe<Scalars['Bytes']>;
  controllerproxy_not_contains?: Maybe<Scalars['Bytes']>;
  aerc721proxy?: Maybe<Scalars['Bytes']>;
  aerc721proxy_not?: Maybe<Scalars['Bytes']>;
  aerc721proxy_in?: Maybe<Array<Scalars['Bytes']>>;
  aerc721proxy_not_in?: Maybe<Array<Scalars['Bytes']>>;
  aerc721proxy_contains?: Maybe<Scalars['Bytes']>;
  aerc721proxy_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum Builded_OrderBy {
  Id = 'id',
  Index = 'index',
  Builder = 'builder',
  Controllerproxy = 'controllerproxy',
  Aerc721proxy = 'aerc721proxy'
}


export type ContractProject = {
  __typename?: 'ContractProject';
  id: Scalars['ID'];
  erc721Contract: Scalars['Bytes'];
  project: Project;
  controllerContract: Scalars['Bytes'];
  projectInfoContract: Scalars['Bytes'];
};

export type ContractProject_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  erc721Contract?: Maybe<Scalars['Bytes']>;
  erc721Contract_not?: Maybe<Scalars['Bytes']>;
  erc721Contract_in?: Maybe<Array<Scalars['Bytes']>>;
  erc721Contract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  erc721Contract_contains?: Maybe<Scalars['Bytes']>;
  erc721Contract_not_contains?: Maybe<Scalars['Bytes']>;
  project?: Maybe<Scalars['String']>;
  project_not?: Maybe<Scalars['String']>;
  project_gt?: Maybe<Scalars['String']>;
  project_lt?: Maybe<Scalars['String']>;
  project_gte?: Maybe<Scalars['String']>;
  project_lte?: Maybe<Scalars['String']>;
  project_in?: Maybe<Array<Scalars['String']>>;
  project_not_in?: Maybe<Array<Scalars['String']>>;
  project_contains?: Maybe<Scalars['String']>;
  project_contains_nocase?: Maybe<Scalars['String']>;
  project_not_contains?: Maybe<Scalars['String']>;
  project_not_contains_nocase?: Maybe<Scalars['String']>;
  project_starts_with?: Maybe<Scalars['String']>;
  project_starts_with_nocase?: Maybe<Scalars['String']>;
  project_not_starts_with?: Maybe<Scalars['String']>;
  project_not_starts_with_nocase?: Maybe<Scalars['String']>;
  project_ends_with?: Maybe<Scalars['String']>;
  project_ends_with_nocase?: Maybe<Scalars['String']>;
  project_not_ends_with?: Maybe<Scalars['String']>;
  project_not_ends_with_nocase?: Maybe<Scalars['String']>;
  project_?: Maybe<Project_Filter>;
  controllerContract?: Maybe<Scalars['Bytes']>;
  controllerContract_not?: Maybe<Scalars['Bytes']>;
  controllerContract_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerContract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerContract_contains?: Maybe<Scalars['Bytes']>;
  controllerContract_not_contains?: Maybe<Scalars['Bytes']>;
  projectInfoContract?: Maybe<Scalars['Bytes']>;
  projectInfoContract_not?: Maybe<Scalars['Bytes']>;
  projectInfoContract_in?: Maybe<Array<Scalars['Bytes']>>;
  projectInfoContract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  projectInfoContract_contains?: Maybe<Scalars['Bytes']>;
  projectInfoContract_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ContractProject_OrderBy {
  Id = 'id',
  Erc721Contract = 'erc721Contract',
  Project = 'project',
  ControllerContract = 'controllerContract',
  ProjectInfoContract = 'projectInfoContract'
}

export type ControllerRoleAdminChanged = {
  __typename?: 'ControllerRoleAdminChanged';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  previousAdminRole: Scalars['Bytes'];
  newAdminRole: Scalars['Bytes'];
};

export type ControllerRoleAdminChanged_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not?: Maybe<Scalars['Bytes']>;
  previousAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole?: Maybe<Scalars['Bytes']>;
  newAdminRole_not?: Maybe<Scalars['Bytes']>;
  newAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ControllerRoleAdminChanged_OrderBy {
  Id = 'id',
  Role = 'role',
  PreviousAdminRole = 'previousAdminRole',
  NewAdminRole = 'newAdminRole'
}

export type ControllerRoleGranted = {
  __typename?: 'ControllerRoleGranted';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type ControllerRoleGranted_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ControllerRoleGranted_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type ControllerRoleRevoked = {
  __typename?: 'ControllerRoleRevoked';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type ControllerRoleRevoked_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ControllerRoleRevoked_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type FactoryRoleAdminChanged = {
  __typename?: 'FactoryRoleAdminChanged';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  previousAdminRole: Scalars['Bytes'];
  newAdminRole: Scalars['Bytes'];
};

export type FactoryRoleAdminChanged_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not?: Maybe<Scalars['Bytes']>;
  previousAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole?: Maybe<Scalars['Bytes']>;
  newAdminRole_not?: Maybe<Scalars['Bytes']>;
  newAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum FactoryRoleAdminChanged_OrderBy {
  Id = 'id',
  Role = 'role',
  PreviousAdminRole = 'previousAdminRole',
  NewAdminRole = 'newAdminRole'
}

export type FactoryRoleGranted = {
  __typename?: 'FactoryRoleGranted';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type FactoryRoleGranted_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum FactoryRoleGranted_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type FactoryRoleRevoked = {
  __typename?: 'FactoryRoleRevoked';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type FactoryRoleRevoked_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum FactoryRoleRevoked_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Project = {
  __typename?: 'Project';
  /** Unique identifier made up of contract address and project id */
  id: Scalars['ID'];
  /** create the project on the contract */
  creator?: Maybe<Array<AccountProject>>;
  /** Percentage of mint revenue allocated to ours */
  mPercentage?: Maybe<Scalars['BigInt']>;
  /** platform fee address */
  platformFeeAddress?: Maybe<Scalars['Bytes']>;
  /** creator receive fee address */
  creatorFeeAddress?: Maybe<Scalars['Bytes']>;
  /** A project is complete when it has reached its maximum supply */
  complete?: Maybe<Scalars['Boolean']>;
  /** price perToken in Wei */
  price?: Maybe<Scalars['BigInt']>;
  /** mint timer */
  timer?: Maybe<Scalars['BigInt']>;
  /** Maximum number of supply allowed for the project */
  maxSupply?: Maybe<Scalars['BigInt']>;
  baseUri?: Maybe<Scalars['String']>;
  /** Number of times the project has been invoked - number of tokens of the project */
  totalSupply?: Maybe<Scalars['BigInt']>;
  /** Project name */
  name?: Maybe<Scalars['String']>;
  tPaused?: Maybe<Scalars['Boolean']>;
  mPaused?: Maybe<Scalars['Boolean']>;
  /** Artist name */
  artistName?: Maybe<Scalars['String']>;
  /** Collection name */
  collectionName?: Maybe<Scalars['String']>;
  /** Artist description of the project */
  description?: Maybe<Scalars['String']>;
  /** License for the project */
  license?: Maybe<Scalars['String']>;
  /** Artist or project engine */
  engine?: Maybe<Scalars['String']>;
  /** Tokens of the project */
  tokens?: Maybe<Array<Token>>;
  createdAt?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['BigInt']>;
  tokenContract: Scalars['Bytes'];
  projectContract: Scalars['Bytes'];
  controllerContract: Scalars['Bytes'];
};


export type ProjectCreatorArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AccountProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<AccountProject_Filter>;
};


export type ProjectTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
};

export type ProjectInfoStorageRoleAdminChanged = {
  __typename?: 'ProjectInfoStorageRoleAdminChanged';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  previousAdminRole: Scalars['Bytes'];
  newAdminRole: Scalars['Bytes'];
};

export type ProjectInfoStorageRoleAdminChanged_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not?: Maybe<Scalars['Bytes']>;
  previousAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole?: Maybe<Scalars['Bytes']>;
  newAdminRole_not?: Maybe<Scalars['Bytes']>;
  newAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ProjectInfoStorageRoleAdminChanged_OrderBy {
  Id = 'id',
  Role = 'role',
  PreviousAdminRole = 'previousAdminRole',
  NewAdminRole = 'newAdminRole'
}

export type ProjectInfoStorageRoleGranted = {
  __typename?: 'ProjectInfoStorageRoleGranted';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type ProjectInfoStorageRoleGranted_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ProjectInfoStorageRoleGranted_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type ProjectInfoStorageRoleRevoked = {
  __typename?: 'ProjectInfoStorageRoleRevoked';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type ProjectInfoStorageRoleRevoked_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum ProjectInfoStorageRoleRevoked_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type Project_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  creator_?: Maybe<AccountProject_Filter>;
  mPercentage?: Maybe<Scalars['BigInt']>;
  mPercentage_not?: Maybe<Scalars['BigInt']>;
  mPercentage_gt?: Maybe<Scalars['BigInt']>;
  mPercentage_lt?: Maybe<Scalars['BigInt']>;
  mPercentage_gte?: Maybe<Scalars['BigInt']>;
  mPercentage_lte?: Maybe<Scalars['BigInt']>;
  mPercentage_in?: Maybe<Array<Scalars['BigInt']>>;
  mPercentage_not_in?: Maybe<Array<Scalars['BigInt']>>;
  platformFeeAddress?: Maybe<Scalars['Bytes']>;
  platformFeeAddress_not?: Maybe<Scalars['Bytes']>;
  platformFeeAddress_in?: Maybe<Array<Scalars['Bytes']>>;
  platformFeeAddress_not_in?: Maybe<Array<Scalars['Bytes']>>;
  platformFeeAddress_contains?: Maybe<Scalars['Bytes']>;
  platformFeeAddress_not_contains?: Maybe<Scalars['Bytes']>;
  creatorFeeAddress?: Maybe<Scalars['Bytes']>;
  creatorFeeAddress_not?: Maybe<Scalars['Bytes']>;
  creatorFeeAddress_in?: Maybe<Array<Scalars['Bytes']>>;
  creatorFeeAddress_not_in?: Maybe<Array<Scalars['Bytes']>>;
  creatorFeeAddress_contains?: Maybe<Scalars['Bytes']>;
  creatorFeeAddress_not_contains?: Maybe<Scalars['Bytes']>;
  complete?: Maybe<Scalars['Boolean']>;
  complete_not?: Maybe<Scalars['Boolean']>;
  complete_in?: Maybe<Array<Scalars['Boolean']>>;
  complete_not_in?: Maybe<Array<Scalars['Boolean']>>;
  price?: Maybe<Scalars['BigInt']>;
  price_not?: Maybe<Scalars['BigInt']>;
  price_gt?: Maybe<Scalars['BigInt']>;
  price_lt?: Maybe<Scalars['BigInt']>;
  price_gte?: Maybe<Scalars['BigInt']>;
  price_lte?: Maybe<Scalars['BigInt']>;
  price_in?: Maybe<Array<Scalars['BigInt']>>;
  price_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timer?: Maybe<Scalars['BigInt']>;
  timer_not?: Maybe<Scalars['BigInt']>;
  timer_gt?: Maybe<Scalars['BigInt']>;
  timer_lt?: Maybe<Scalars['BigInt']>;
  timer_gte?: Maybe<Scalars['BigInt']>;
  timer_lte?: Maybe<Scalars['BigInt']>;
  timer_in?: Maybe<Array<Scalars['BigInt']>>;
  timer_not_in?: Maybe<Array<Scalars['BigInt']>>;
  maxSupply?: Maybe<Scalars['BigInt']>;
  maxSupply_not?: Maybe<Scalars['BigInt']>;
  maxSupply_gt?: Maybe<Scalars['BigInt']>;
  maxSupply_lt?: Maybe<Scalars['BigInt']>;
  maxSupply_gte?: Maybe<Scalars['BigInt']>;
  maxSupply_lte?: Maybe<Scalars['BigInt']>;
  maxSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  maxSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  baseUri?: Maybe<Scalars['String']>;
  baseUri_not?: Maybe<Scalars['String']>;
  baseUri_gt?: Maybe<Scalars['String']>;
  baseUri_lt?: Maybe<Scalars['String']>;
  baseUri_gte?: Maybe<Scalars['String']>;
  baseUri_lte?: Maybe<Scalars['String']>;
  baseUri_in?: Maybe<Array<Scalars['String']>>;
  baseUri_not_in?: Maybe<Array<Scalars['String']>>;
  baseUri_contains?: Maybe<Scalars['String']>;
  baseUri_contains_nocase?: Maybe<Scalars['String']>;
  baseUri_not_contains?: Maybe<Scalars['String']>;
  baseUri_not_contains_nocase?: Maybe<Scalars['String']>;
  baseUri_starts_with?: Maybe<Scalars['String']>;
  baseUri_starts_with_nocase?: Maybe<Scalars['String']>;
  baseUri_not_starts_with?: Maybe<Scalars['String']>;
  baseUri_not_starts_with_nocase?: Maybe<Scalars['String']>;
  baseUri_ends_with?: Maybe<Scalars['String']>;
  baseUri_ends_with_nocase?: Maybe<Scalars['String']>;
  baseUri_not_ends_with?: Maybe<Scalars['String']>;
  baseUri_not_ends_with_nocase?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['BigInt']>;
  totalSupply_not?: Maybe<Scalars['BigInt']>;
  totalSupply_gt?: Maybe<Scalars['BigInt']>;
  totalSupply_lt?: Maybe<Scalars['BigInt']>;
  totalSupply_gte?: Maybe<Scalars['BigInt']>;
  totalSupply_lte?: Maybe<Scalars['BigInt']>;
  totalSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tPaused?: Maybe<Scalars['Boolean']>;
  tPaused_not?: Maybe<Scalars['Boolean']>;
  tPaused_in?: Maybe<Array<Scalars['Boolean']>>;
  tPaused_not_in?: Maybe<Array<Scalars['Boolean']>>;
  mPaused?: Maybe<Scalars['Boolean']>;
  mPaused_not?: Maybe<Scalars['Boolean']>;
  mPaused_in?: Maybe<Array<Scalars['Boolean']>>;
  mPaused_not_in?: Maybe<Array<Scalars['Boolean']>>;
  artistName?: Maybe<Scalars['String']>;
  artistName_not?: Maybe<Scalars['String']>;
  artistName_gt?: Maybe<Scalars['String']>;
  artistName_lt?: Maybe<Scalars['String']>;
  artistName_gte?: Maybe<Scalars['String']>;
  artistName_lte?: Maybe<Scalars['String']>;
  artistName_in?: Maybe<Array<Scalars['String']>>;
  artistName_not_in?: Maybe<Array<Scalars['String']>>;
  artistName_contains?: Maybe<Scalars['String']>;
  artistName_contains_nocase?: Maybe<Scalars['String']>;
  artistName_not_contains?: Maybe<Scalars['String']>;
  artistName_not_contains_nocase?: Maybe<Scalars['String']>;
  artistName_starts_with?: Maybe<Scalars['String']>;
  artistName_starts_with_nocase?: Maybe<Scalars['String']>;
  artistName_not_starts_with?: Maybe<Scalars['String']>;
  artistName_not_starts_with_nocase?: Maybe<Scalars['String']>;
  artistName_ends_with?: Maybe<Scalars['String']>;
  artistName_ends_with_nocase?: Maybe<Scalars['String']>;
  artistName_not_ends_with?: Maybe<Scalars['String']>;
  artistName_not_ends_with_nocase?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  collectionName_not?: Maybe<Scalars['String']>;
  collectionName_gt?: Maybe<Scalars['String']>;
  collectionName_lt?: Maybe<Scalars['String']>;
  collectionName_gte?: Maybe<Scalars['String']>;
  collectionName_lte?: Maybe<Scalars['String']>;
  collectionName_in?: Maybe<Array<Scalars['String']>>;
  collectionName_not_in?: Maybe<Array<Scalars['String']>>;
  collectionName_contains?: Maybe<Scalars['String']>;
  collectionName_contains_nocase?: Maybe<Scalars['String']>;
  collectionName_not_contains?: Maybe<Scalars['String']>;
  collectionName_not_contains_nocase?: Maybe<Scalars['String']>;
  collectionName_starts_with?: Maybe<Scalars['String']>;
  collectionName_starts_with_nocase?: Maybe<Scalars['String']>;
  collectionName_not_starts_with?: Maybe<Scalars['String']>;
  collectionName_not_starts_with_nocase?: Maybe<Scalars['String']>;
  collectionName_ends_with?: Maybe<Scalars['String']>;
  collectionName_ends_with_nocase?: Maybe<Scalars['String']>;
  collectionName_not_ends_with?: Maybe<Scalars['String']>;
  collectionName_not_ends_with_nocase?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_lt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_contains?: Maybe<Scalars['String']>;
  description_contains_nocase?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_not_contains_nocase?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_starts_with_nocase?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with_nocase?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_ends_with_nocase?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with_nocase?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  license_not?: Maybe<Scalars['String']>;
  license_gt?: Maybe<Scalars['String']>;
  license_lt?: Maybe<Scalars['String']>;
  license_gte?: Maybe<Scalars['String']>;
  license_lte?: Maybe<Scalars['String']>;
  license_in?: Maybe<Array<Scalars['String']>>;
  license_not_in?: Maybe<Array<Scalars['String']>>;
  license_contains?: Maybe<Scalars['String']>;
  license_contains_nocase?: Maybe<Scalars['String']>;
  license_not_contains?: Maybe<Scalars['String']>;
  license_not_contains_nocase?: Maybe<Scalars['String']>;
  license_starts_with?: Maybe<Scalars['String']>;
  license_starts_with_nocase?: Maybe<Scalars['String']>;
  license_not_starts_with?: Maybe<Scalars['String']>;
  license_not_starts_with_nocase?: Maybe<Scalars['String']>;
  license_ends_with?: Maybe<Scalars['String']>;
  license_ends_with_nocase?: Maybe<Scalars['String']>;
  license_not_ends_with?: Maybe<Scalars['String']>;
  license_not_ends_with_nocase?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  engine_not?: Maybe<Scalars['String']>;
  engine_gt?: Maybe<Scalars['String']>;
  engine_lt?: Maybe<Scalars['String']>;
  engine_gte?: Maybe<Scalars['String']>;
  engine_lte?: Maybe<Scalars['String']>;
  engine_in?: Maybe<Array<Scalars['String']>>;
  engine_not_in?: Maybe<Array<Scalars['String']>>;
  engine_contains?: Maybe<Scalars['String']>;
  engine_contains_nocase?: Maybe<Scalars['String']>;
  engine_not_contains?: Maybe<Scalars['String']>;
  engine_not_contains_nocase?: Maybe<Scalars['String']>;
  engine_starts_with?: Maybe<Scalars['String']>;
  engine_starts_with_nocase?: Maybe<Scalars['String']>;
  engine_not_starts_with?: Maybe<Scalars['String']>;
  engine_not_starts_with_nocase?: Maybe<Scalars['String']>;
  engine_ends_with?: Maybe<Scalars['String']>;
  engine_ends_with_nocase?: Maybe<Scalars['String']>;
  engine_not_ends_with?: Maybe<Scalars['String']>;
  engine_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tokens_?: Maybe<Token_Filter>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdAt_not?: Maybe<Scalars['BigInt']>;
  createdAt_gt?: Maybe<Scalars['BigInt']>;
  createdAt_lt?: Maybe<Scalars['BigInt']>;
  createdAt_gte?: Maybe<Scalars['BigInt']>;
  createdAt_lte?: Maybe<Scalars['BigInt']>;
  createdAt_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAt_not_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAt?: Maybe<Scalars['BigInt']>;
  updatedAt_not?: Maybe<Scalars['BigInt']>;
  updatedAt_gt?: Maybe<Scalars['BigInt']>;
  updatedAt_lt?: Maybe<Scalars['BigInt']>;
  updatedAt_gte?: Maybe<Scalars['BigInt']>;
  updatedAt_lte?: Maybe<Scalars['BigInt']>;
  updatedAt_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tokenContract?: Maybe<Scalars['Bytes']>;
  tokenContract_not?: Maybe<Scalars['Bytes']>;
  tokenContract_in?: Maybe<Array<Scalars['Bytes']>>;
  tokenContract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  tokenContract_contains?: Maybe<Scalars['Bytes']>;
  tokenContract_not_contains?: Maybe<Scalars['Bytes']>;
  projectContract?: Maybe<Scalars['Bytes']>;
  projectContract_not?: Maybe<Scalars['Bytes']>;
  projectContract_in?: Maybe<Array<Scalars['Bytes']>>;
  projectContract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  projectContract_contains?: Maybe<Scalars['Bytes']>;
  projectContract_not_contains?: Maybe<Scalars['Bytes']>;
  controllerContract?: Maybe<Scalars['Bytes']>;
  controllerContract_not?: Maybe<Scalars['Bytes']>;
  controllerContract_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerContract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  controllerContract_contains?: Maybe<Scalars['Bytes']>;
  controllerContract_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum Project_OrderBy {
  Id = 'id',
  Creator = 'creator',
  MPercentage = 'mPercentage',
  PlatformFeeAddress = 'platformFeeAddress',
  CreatorFeeAddress = 'creatorFeeAddress',
  Complete = 'complete',
  Price = 'price',
  Timer = 'timer',
  MaxSupply = 'maxSupply',
  BaseUri = 'baseUri',
  TotalSupply = 'totalSupply',
  Name = 'name',
  TPaused = 'tPaused',
  MPaused = 'mPaused',
  ArtistName = 'artistName',
  CollectionName = 'collectionName',
  Description = 'description',
  License = 'license',
  Engine = 'engine',
  Tokens = 'tokens',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TokenContract = 'tokenContract',
  ProjectContract = 'projectContract',
  ControllerContract = 'controllerContract'
}

export type Query = {
  __typename?: 'Query';
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  builded?: Maybe<Builded>;
  buildeds: Array<Builded>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  accountProject?: Maybe<AccountProject>;
  accountProjects: Array<AccountProject>;
  contractProject?: Maybe<ContractProject>;
  contractProjects: Array<ContractProject>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  projectInfoStorageRoleAdminChanged?: Maybe<ProjectInfoStorageRoleAdminChanged>;
  projectInfoStorageRoleAdminChangeds: Array<ProjectInfoStorageRoleAdminChanged>;
  projectInfoStorageRoleGranted?: Maybe<ProjectInfoStorageRoleGranted>;
  projectInfoStorageRoleGranteds: Array<ProjectInfoStorageRoleGranted>;
  projectInfoStorageRoleRevoked?: Maybe<ProjectInfoStorageRoleRevoked>;
  projectInfoStorageRoleRevokeds: Array<ProjectInfoStorageRoleRevoked>;
  factoryRoleAdminChanged?: Maybe<FactoryRoleAdminChanged>;
  factoryRoleAdminChangeds: Array<FactoryRoleAdminChanged>;
  factoryRoleGranted?: Maybe<FactoryRoleGranted>;
  factoryRoleGranteds: Array<FactoryRoleGranted>;
  factoryRoleRevoked?: Maybe<FactoryRoleRevoked>;
  factoryRoleRevokeds: Array<FactoryRoleRevoked>;
  controllerRoleAdminChanged?: Maybe<ControllerRoleAdminChanged>;
  controllerRoleAdminChangeds: Array<ControllerRoleAdminChanged>;
  controllerRoleGranted?: Maybe<ControllerRoleGranted>;
  controllerRoleGranteds: Array<ControllerRoleGranted>;
  controllerRoleRevoked?: Maybe<ControllerRoleRevoked>;
  controllerRoleRevokeds: Array<ControllerRoleRevoked>;
  roleAdminChanged?: Maybe<RoleAdminChanged>;
  roleAdminChangeds: Array<RoleAdminChanged>;
  roleGranted?: Maybe<RoleGranted>;
  roleGranteds: Array<RoleGranted>;
  roleRevoked?: Maybe<RoleRevoked>;
  roleRevokeds: Array<RoleRevoked>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryTransferArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransfersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Transfer_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Transfer_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBuildedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBuildedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Builded_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Builded_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Project_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Project_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AccountProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<AccountProject_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryContractProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryContractProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ContractProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ContractProject_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProjectInfoStorageRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoryRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryControllerRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type RoleAdminChanged = {
  __typename?: 'RoleAdminChanged';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  previousAdminRole: Scalars['Bytes'];
  newAdminRole: Scalars['Bytes'];
};

export type RoleAdminChanged_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not?: Maybe<Scalars['Bytes']>;
  previousAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  previousAdminRole_contains?: Maybe<Scalars['Bytes']>;
  previousAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole?: Maybe<Scalars['Bytes']>;
  newAdminRole_not?: Maybe<Scalars['Bytes']>;
  newAdminRole_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_not_in?: Maybe<Array<Scalars['Bytes']>>;
  newAdminRole_contains?: Maybe<Scalars['Bytes']>;
  newAdminRole_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum RoleAdminChanged_OrderBy {
  Id = 'id',
  Role = 'role',
  PreviousAdminRole = 'previousAdminRole',
  NewAdminRole = 'newAdminRole'
}

export type RoleGranted = {
  __typename?: 'RoleGranted';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type RoleGranted_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum RoleGranted_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type RoleRevoked = {
  __typename?: 'RoleRevoked';
  id: Scalars['ID'];
  role: Scalars['Bytes'];
  account: Scalars['Bytes'];
  sender: Scalars['Bytes'];
};

export type RoleRevoked_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<Scalars['Bytes']>;
  role_not?: Maybe<Scalars['Bytes']>;
  role_in?: Maybe<Array<Scalars['Bytes']>>;
  role_not_in?: Maybe<Array<Scalars['Bytes']>>;
  role_contains?: Maybe<Scalars['Bytes']>;
  role_not_contains?: Maybe<Scalars['Bytes']>;
  account?: Maybe<Scalars['Bytes']>;
  account_not?: Maybe<Scalars['Bytes']>;
  account_in?: Maybe<Array<Scalars['Bytes']>>;
  account_not_in?: Maybe<Array<Scalars['Bytes']>>;
  account_contains?: Maybe<Scalars['Bytes']>;
  account_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum RoleRevoked_OrderBy {
  Id = 'id',
  Role = 'role',
  Account = 'account',
  Sender = 'sender'
}

export type Subscription = {
  __typename?: 'Subscription';
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  builded?: Maybe<Builded>;
  buildeds: Array<Builded>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  accountProject?: Maybe<AccountProject>;
  accountProjects: Array<AccountProject>;
  contractProject?: Maybe<ContractProject>;
  contractProjects: Array<ContractProject>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  projectInfoStorageRoleAdminChanged?: Maybe<ProjectInfoStorageRoleAdminChanged>;
  projectInfoStorageRoleAdminChangeds: Array<ProjectInfoStorageRoleAdminChanged>;
  projectInfoStorageRoleGranted?: Maybe<ProjectInfoStorageRoleGranted>;
  projectInfoStorageRoleGranteds: Array<ProjectInfoStorageRoleGranted>;
  projectInfoStorageRoleRevoked?: Maybe<ProjectInfoStorageRoleRevoked>;
  projectInfoStorageRoleRevokeds: Array<ProjectInfoStorageRoleRevoked>;
  factoryRoleAdminChanged?: Maybe<FactoryRoleAdminChanged>;
  factoryRoleAdminChangeds: Array<FactoryRoleAdminChanged>;
  factoryRoleGranted?: Maybe<FactoryRoleGranted>;
  factoryRoleGranteds: Array<FactoryRoleGranted>;
  factoryRoleRevoked?: Maybe<FactoryRoleRevoked>;
  factoryRoleRevokeds: Array<FactoryRoleRevoked>;
  controllerRoleAdminChanged?: Maybe<ControllerRoleAdminChanged>;
  controllerRoleAdminChangeds: Array<ControllerRoleAdminChanged>;
  controllerRoleGranted?: Maybe<ControllerRoleGranted>;
  controllerRoleGranteds: Array<ControllerRoleGranted>;
  controllerRoleRevoked?: Maybe<ControllerRoleRevoked>;
  controllerRoleRevokeds: Array<ControllerRoleRevoked>;
  roleAdminChanged?: Maybe<RoleAdminChanged>;
  roleAdminChangeds: Array<RoleAdminChanged>;
  roleGranted?: Maybe<RoleGranted>;
  roleGranteds: Array<RoleGranted>;
  roleRevoked?: Maybe<RoleRevoked>;
  roleRevokeds: Array<RoleRevoked>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionTransferArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransfersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Transfer_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Transfer_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBuildedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBuildedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Builded_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Builded_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Project_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Project_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AccountProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<AccountProject_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionContractProjectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionContractProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ContractProject_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ContractProject_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProjectInfoStorageRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectInfoStorageRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ProjectInfoStorageRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoryRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<FactoryRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<FactoryRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionControllerRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ControllerRoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ControllerRoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleAdminChangedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleAdminChangedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleAdminChanged_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleAdminChanged_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleGrantedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleGrantedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleGranted_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleGranted_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleRevokedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleRevokedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RoleRevoked_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RoleRevoked_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Token = {
  __typename?: 'Token';
  /** Unique identifier made up of contract address and token id */
  id: Scalars['ID'];
  /** ID of the token on the contract */
  tokenId: Scalars['BigInt'];
  /** Contract the token is on */
  contract: Scalars['Bytes'];
  /** current supply number of the project */
  supply: Scalars['BigInt'];
  /** Current owner of the token */
  owner: Account;
  /** Project of the token */
  project: Project;
  uri?: Maybe<Scalars['String']>;
  createdAt: Scalars['BigInt'];
  updatedAt: Scalars['BigInt'];
  /** Transaction hash of token mint */
  transactionHash: Scalars['Bytes'];
};

export type Token_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  tokenId?: Maybe<Scalars['BigInt']>;
  tokenId_not?: Maybe<Scalars['BigInt']>;
  tokenId_gt?: Maybe<Scalars['BigInt']>;
  tokenId_lt?: Maybe<Scalars['BigInt']>;
  tokenId_gte?: Maybe<Scalars['BigInt']>;
  tokenId_lte?: Maybe<Scalars['BigInt']>;
  tokenId_in?: Maybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  contract?: Maybe<Scalars['Bytes']>;
  contract_not?: Maybe<Scalars['Bytes']>;
  contract_in?: Maybe<Array<Scalars['Bytes']>>;
  contract_not_in?: Maybe<Array<Scalars['Bytes']>>;
  contract_contains?: Maybe<Scalars['Bytes']>;
  contract_not_contains?: Maybe<Scalars['Bytes']>;
  supply?: Maybe<Scalars['BigInt']>;
  supply_not?: Maybe<Scalars['BigInt']>;
  supply_gt?: Maybe<Scalars['BigInt']>;
  supply_lt?: Maybe<Scalars['BigInt']>;
  supply_gte?: Maybe<Scalars['BigInt']>;
  supply_lte?: Maybe<Scalars['BigInt']>;
  supply_in?: Maybe<Array<Scalars['BigInt']>>;
  supply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  owner?: Maybe<Scalars['String']>;
  owner_not?: Maybe<Scalars['String']>;
  owner_gt?: Maybe<Scalars['String']>;
  owner_lt?: Maybe<Scalars['String']>;
  owner_gte?: Maybe<Scalars['String']>;
  owner_lte?: Maybe<Scalars['String']>;
  owner_in?: Maybe<Array<Scalars['String']>>;
  owner_not_in?: Maybe<Array<Scalars['String']>>;
  owner_contains?: Maybe<Scalars['String']>;
  owner_contains_nocase?: Maybe<Scalars['String']>;
  owner_not_contains?: Maybe<Scalars['String']>;
  owner_not_contains_nocase?: Maybe<Scalars['String']>;
  owner_starts_with?: Maybe<Scalars['String']>;
  owner_starts_with_nocase?: Maybe<Scalars['String']>;
  owner_not_starts_with?: Maybe<Scalars['String']>;
  owner_not_starts_with_nocase?: Maybe<Scalars['String']>;
  owner_ends_with?: Maybe<Scalars['String']>;
  owner_ends_with_nocase?: Maybe<Scalars['String']>;
  owner_not_ends_with?: Maybe<Scalars['String']>;
  owner_not_ends_with_nocase?: Maybe<Scalars['String']>;
  owner_?: Maybe<Account_Filter>;
  project?: Maybe<Scalars['String']>;
  project_not?: Maybe<Scalars['String']>;
  project_gt?: Maybe<Scalars['String']>;
  project_lt?: Maybe<Scalars['String']>;
  project_gte?: Maybe<Scalars['String']>;
  project_lte?: Maybe<Scalars['String']>;
  project_in?: Maybe<Array<Scalars['String']>>;
  project_not_in?: Maybe<Array<Scalars['String']>>;
  project_contains?: Maybe<Scalars['String']>;
  project_contains_nocase?: Maybe<Scalars['String']>;
  project_not_contains?: Maybe<Scalars['String']>;
  project_not_contains_nocase?: Maybe<Scalars['String']>;
  project_starts_with?: Maybe<Scalars['String']>;
  project_starts_with_nocase?: Maybe<Scalars['String']>;
  project_not_starts_with?: Maybe<Scalars['String']>;
  project_not_starts_with_nocase?: Maybe<Scalars['String']>;
  project_ends_with?: Maybe<Scalars['String']>;
  project_ends_with_nocase?: Maybe<Scalars['String']>;
  project_not_ends_with?: Maybe<Scalars['String']>;
  project_not_ends_with_nocase?: Maybe<Scalars['String']>;
  project_?: Maybe<Project_Filter>;
  uri?: Maybe<Scalars['String']>;
  uri_not?: Maybe<Scalars['String']>;
  uri_gt?: Maybe<Scalars['String']>;
  uri_lt?: Maybe<Scalars['String']>;
  uri_gte?: Maybe<Scalars['String']>;
  uri_lte?: Maybe<Scalars['String']>;
  uri_in?: Maybe<Array<Scalars['String']>>;
  uri_not_in?: Maybe<Array<Scalars['String']>>;
  uri_contains?: Maybe<Scalars['String']>;
  uri_contains_nocase?: Maybe<Scalars['String']>;
  uri_not_contains?: Maybe<Scalars['String']>;
  uri_not_contains_nocase?: Maybe<Scalars['String']>;
  uri_starts_with?: Maybe<Scalars['String']>;
  uri_starts_with_nocase?: Maybe<Scalars['String']>;
  uri_not_starts_with?: Maybe<Scalars['String']>;
  uri_not_starts_with_nocase?: Maybe<Scalars['String']>;
  uri_ends_with?: Maybe<Scalars['String']>;
  uri_ends_with_nocase?: Maybe<Scalars['String']>;
  uri_not_ends_with?: Maybe<Scalars['String']>;
  uri_not_ends_with_nocase?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdAt_not?: Maybe<Scalars['BigInt']>;
  createdAt_gt?: Maybe<Scalars['BigInt']>;
  createdAt_lt?: Maybe<Scalars['BigInt']>;
  createdAt_gte?: Maybe<Scalars['BigInt']>;
  createdAt_lte?: Maybe<Scalars['BigInt']>;
  createdAt_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAt_not_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAt?: Maybe<Scalars['BigInt']>;
  updatedAt_not?: Maybe<Scalars['BigInt']>;
  updatedAt_gt?: Maybe<Scalars['BigInt']>;
  updatedAt_lt?: Maybe<Scalars['BigInt']>;
  updatedAt_gte?: Maybe<Scalars['BigInt']>;
  updatedAt_lte?: Maybe<Scalars['BigInt']>;
  updatedAt_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['BigInt']>>;
  transactionHash?: Maybe<Scalars['Bytes']>;
  transactionHash_not?: Maybe<Scalars['Bytes']>;
  transactionHash_in?: Maybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: Maybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: Maybe<Scalars['Bytes']>;
  transactionHash_not_contains?: Maybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum Token_OrderBy {
  Id = 'id',
  TokenId = 'tokenId',
  Contract = 'contract',
  Supply = 'supply',
  Owner = 'owner',
  Project = 'project',
  Uri = 'uri',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TransactionHash = 'transactionHash'
}

export type Transfer = {
  __typename?: 'Transfer';
  id: Scalars['ID'];
  from: Scalars['Bytes'];
  to: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
};

export type Transfer_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  from?: Maybe<Scalars['Bytes']>;
  from_not?: Maybe<Scalars['Bytes']>;
  from_in?: Maybe<Array<Scalars['Bytes']>>;
  from_not_in?: Maybe<Array<Scalars['Bytes']>>;
  from_contains?: Maybe<Scalars['Bytes']>;
  from_not_contains?: Maybe<Scalars['Bytes']>;
  to?: Maybe<Scalars['Bytes']>;
  to_not?: Maybe<Scalars['Bytes']>;
  to_in?: Maybe<Array<Scalars['Bytes']>>;
  to_not_in?: Maybe<Array<Scalars['Bytes']>>;
  to_contains?: Maybe<Scalars['Bytes']>;
  to_not_contains?: Maybe<Scalars['Bytes']>;
  tokenId?: Maybe<Scalars['BigInt']>;
  tokenId_not?: Maybe<Scalars['BigInt']>;
  tokenId_gt?: Maybe<Scalars['BigInt']>;
  tokenId_lt?: Maybe<Scalars['BigInt']>;
  tokenId_gte?: Maybe<Scalars['BigInt']>;
  tokenId_lte?: Maybe<Scalars['BigInt']>;
  tokenId_in?: Maybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
};

export enum Transfer_OrderBy {
  Id = 'id',
  From = 'from',
  To = 'to',
  TokenId = 'tokenId'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type AllProjectsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type AllProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'artistName' | 'baseUri' | 'collectionName' | 'complete' | 'controllerContract' | 'createdAt' | 'creatorFeeAddress' | 'description' | 'engine' | 'id' | 'license' | 'mPaused' | 'mPercentage' | 'maxSupply' | 'name' | 'platformFeeAddress' | 'price' | 'projectContract' | 'tPaused' | 'timer' | 'tokenContract' | 'totalSupply' | 'updatedAt'>
    & { creator?: Maybe<Array<(
      { __typename?: 'AccountProject' }
      & Pick<AccountProject, 'id'>
    )>>, tokens?: Maybe<Array<(
      { __typename?: 'Token' }
      & Pick<Token, 'createdAt' | 'contract' | 'id' | 'supply' | 'tokenId' | 'transactionHash' | 'updatedAt' | 'uri'>
      & { owner: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
      ) }
    )>> }
  )> }
);

export type ProjectDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectDetailQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'artistName' | 'baseUri' | 'collectionName' | 'complete' | 'controllerContract' | 'createdAt' | 'creatorFeeAddress' | 'description' | 'engine' | 'id' | 'license' | 'mPaused' | 'mPercentage' | 'maxSupply' | 'name' | 'platformFeeAddress' | 'price' | 'projectContract' | 'tPaused' | 'timer' | 'tokenContract' | 'totalSupply' | 'updatedAt'>
    & { creator?: Maybe<Array<(
      { __typename?: 'AccountProject' }
      & Pick<AccountProject, 'id'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
      ) }
    )>>, tokens?: Maybe<Array<(
      { __typename?: 'Token' }
      & Pick<Token, 'createdAt' | 'contract' | 'id' | 'supply' | 'tokenId' | 'transactionHash' | 'updatedAt' | 'uri'>
      & { owner: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
      ) }
    )>> }
  )> }
);

export type AccountProjectsQueryVariables = Exact<{
  account: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type AccountProjectsQuery = (
  { __typename?: 'Query' }
  & { accountProjects: Array<(
    { __typename?: 'AccountProject' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    ), project: (
      { __typename?: 'Project' }
      & Pick<Project, 'artistName' | 'baseUri' | 'collectionName' | 'complete' | 'controllerContract' | 'createdAt' | 'creatorFeeAddress' | 'description' | 'engine' | 'id' | 'license' | 'mPaused' | 'mPercentage' | 'maxSupply' | 'name' | 'platformFeeAddress' | 'price' | 'projectContract' | 'tPaused' | 'timer' | 'tokenContract' | 'totalSupply' | 'updatedAt'>
      & { creator?: Maybe<Array<(
        { __typename?: 'AccountProject' }
        & Pick<AccountProject, 'id'>
      )>>, tokens?: Maybe<Array<(
        { __typename?: 'Token' }
        & Pick<Token, 'createdAt' | 'contract' | 'id' | 'supply' | 'tokenId' | 'transactionHash' | 'updatedAt' | 'uri'>
        & { owner: (
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        ) }
      )>> }
    ) }
  )> }
);

export type AllTokensQueryVariables = Exact<{
  account: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type AllTokensQuery = (
  { __typename?: 'Query' }
  & { tokens: Array<(
    { __typename?: 'Token' }
    & Pick<Token, 'id' | 'tokenId' | 'contract' | 'uri'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'artistName' | 'baseUri' | 'collectionName' | 'complete' | 'controllerContract' | 'createdAt' | 'creatorFeeAddress' | 'description' | 'engine' | 'id' | 'license' | 'mPaused' | 'mPercentage' | 'maxSupply' | 'name' | 'platformFeeAddress' | 'price' | 'projectContract' | 'tPaused' | 'timer' | 'tokenContract' | 'totalSupply' | 'updatedAt'>
      & { creator?: Maybe<Array<(
        { __typename?: 'AccountProject' }
        & Pick<AccountProject, 'id'>
      )>>, tokens?: Maybe<Array<(
        { __typename?: 'Token' }
        & Pick<Token, 'createdAt' | 'contract' | 'id' | 'supply' | 'tokenId' | 'transactionHash' | 'updatedAt' | 'uri'>
        & { owner: (
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        ) }
      )>> }
    ) }
  )> }
);


export const AllProjectsDocument = `
    query allProjects($first: Int!, $skip: Int!) {
  projects(first: $first, skip: $skip, orderBy: createdAt, orderDirection: desc) {
    artistName
    baseUri
    collectionName
    complete
    controllerContract
    createdAt
    creatorFeeAddress
    creator(first: 10) {
      id
    }
    description
    engine
    id
    license
    mPaused
    mPercentage
    maxSupply
    name
    platformFeeAddress
    price
    projectContract
    tPaused
    timer
    tokenContract
    tokens(first: 1) {
      createdAt
      contract
      id
      supply
      tokenId
      transactionHash
      updatedAt
      uri
      owner {
        id
      }
    }
    totalSupply
    updatedAt
  }
}
    `;
export const ProjectDetailDocument = `
    query projectDetail($id: ID!) {
  project(id: $id) {
    artistName
    baseUri
    collectionName
    complete
    controllerContract
    createdAt
    creatorFeeAddress
    creator(first: 10) {
      account {
        id
      }
      id
    }
    description
    engine
    id
    license
    mPaused
    mPercentage
    maxSupply
    name
    platformFeeAddress
    price
    projectContract
    tPaused
    timer
    tokenContract
    tokens {
      createdAt
      contract
      id
      supply
      tokenId
      transactionHash
      updatedAt
      uri
      owner {
        id
      }
    }
    totalSupply
    updatedAt
  }
}
    `;
export const AccountProjectsDocument = `
    query accountProjects($account: String!, $first: Int!, $skip: Int!) {
  accountProjects(first: $first, skip: $skip, where: {account: $account}) {
    account {
      id
    }
    project {
      artistName
      baseUri
      collectionName
      complete
      controllerContract
      createdAt
      creatorFeeAddress
      creator(first: 10) {
        id
      }
      description
      engine
      id
      license
      mPaused
      mPercentage
      maxSupply
      name
      platformFeeAddress
      price
      projectContract
      tPaused
      timer
      tokenContract
      tokens(first: 1) {
        createdAt
        contract
        id
        supply
        tokenId
        transactionHash
        updatedAt
        uri
        owner {
          id
        }
      }
      totalSupply
      updatedAt
    }
  }
}
    `;
export const AllTokensDocument = `
    query allTokens($account: String!, $first: Int!, $skip: Int!) {
  tokens(
    first: $first
    skip: $skip
    orderBy: createdAt
    orderDirection: desc
    where: {owner: $account}
  ) {
    id
    tokenId
    contract
    uri
    project {
      artistName
      baseUri
      collectionName
      complete
      controllerContract
      createdAt
      creatorFeeAddress
      creator(first: 10) {
        id
      }
      description
      engine
      id
      license
      mPaused
      mPercentage
      maxSupply
      name
      platformFeeAddress
      price
      projectContract
      tPaused
      timer
      tokenContract
      tokens(first: 1) {
        createdAt
        contract
        id
        supply
        tokenId
        transactionHash
        updatedAt
        uri
        owner {
          id
        }
      }
      totalSupply
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    allProjects: build.query<AllProjectsQuery, AllProjectsQueryVariables>({
      query: (variables) => ({ document: AllProjectsDocument, variables })
    }),
    projectDetail: build.query<ProjectDetailQuery, ProjectDetailQueryVariables>({
      query: (variables) => ({ document: ProjectDetailDocument, variables })
    }),
    accountProjects: build.query<AccountProjectsQuery, AccountProjectsQueryVariables>({
      query: (variables) => ({ document: AccountProjectsDocument, variables })
    }),
    allTokens: build.query<AllTokensQuery, AllTokensQueryVariables>({
      query: (variables) => ({ document: AllTokensDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAllProjectsQuery, useLazyAllProjectsQuery, useProjectDetailQuery, useLazyProjectDetailQuery, useAccountProjectsQuery, useLazyAccountProjectsQuery, useAllTokensQuery, useLazyAllTokensQuery } = injectedRtkApi;

