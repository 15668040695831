import Success from 'assets/images/SubmitSuccess.png'
import * as S from 'pages/Submit/styles'
import { useHistory } from 'react-router-dom'

export default function SubmitSuccess({ onNextClick }: { onNextClick: () => void }) {
  const history = useHistory()
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <img
          src={Success}
          loading="lazy"
          sizes="(max-width: 991px) 90vw, (max-width: 3413px) 60vw, 2048px"
          alt="Submit Success"
          className="image-8"
        />
        <div className="text-block-14">
          Thank you! Your submission has been received! We look forward to reviewing your Submission.
          <br />
          We will email you regarding your submission.
        </div>
        <S.SuccessBtnPanel>
          <div onClick={() => history.push('/')} className="back-home w-button">
            Back Home
          </div>
          <div onClick={onNextClick} className="submit-another-one w-button">
            Submit Another One
          </div>
        </S.SuccessBtnPanel>
      </div>
    </div>
  )
}
