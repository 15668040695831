// import { AdvancedImage, placeholder } from '@cloudinary/react'
// import { CloudinaryImage } from '@cloudinary/url-gen'
// import { blur } from '@cloudinary/url-gen/actions/effect'
import { useWeb3React } from '@web3-react/core'
import Loading from 'components/Loading/Loading'
import { SupportedChainId } from 'constants/chains'
import { PublicUrl } from 'constants/publicurl'
// import { GetCloudName, PublicUrl } from 'constants/publicurl'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import { useEffect, useState } from 'react'

export default function TokenImage({
  uri,
  className,
  loading,
  sizes,
  alt,
  callback,
  onLoad
}: {
  uri: string
  className?: string
  loading: string
  sizes: string
  alt: string
  callback?: (image: string) => void
  onLoad?: (uri: string) => void
}) {
  const fetchData = useFetchTokenUriDataCallback()
  const [image, setImage] = useState(null as any)
  const [showLoading, setShowLoading] = useState(true)
  const { chainId } = useWeb3React()

  useEffect(() => {
    fetchData(uri)
      .then((data) => {
        setImage(
          // new CloudinaryImage(data?.image, {
          //   cloudName: GetCloudName(chainId ?? SupportedChainId.MAINNET)
          // })
          //   .setDeliveryType('fetch')
          //   .quality('auto')
          //   .format('auto')
          //   .effect(blur())
          //   .toURL()
          data?.image
        )
        if (callback) {
          callback(data.image)
        }
      })
      .catch((error) => {
        console.debug('fetching data error', error)
        setImage(
          // new CloudinaryImage(PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/default-image.png', {
          //   cloudName: GetCloudName(chainId ?? SupportedChainId.MAINNET)
          // })
          //   .setDeliveryType('fetch')
          //   .quality('auto')
          //   .format('auto')
          //   .effect(blur())
          //   .toURL()
          PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/default-image.png'
        )
      })
  }, [callback, chainId, fetchData, uri])

  return (
    <>
      {image && (
        <img
          className={className}
          src={image}
          onLoad={() => {
            console.log('onLoad')
            if (showLoading) setShowLoading(false)
            if (onLoad) onLoad(uri)
          }}
          onError={() => {
            console.log('onError')
            setImage(
              // new CloudinaryImage(PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/default-image.png', {
              //   cloudName: GetCloudName(chainId ?? SupportedChainId.MAINNET)
              // })
              //   .setDeliveryType('fetch')
              //   .quality('auto')
              //   .format('auto')
              //   .effect(blur())
              //   .toURL()
              PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/default-image.png'
            )
          }}
          alt={uri}
          // plugins={[placeholder({ mode: 'blur' })]}
        />
      )}
      {showLoading && <Loading></Loading>}
    </>
  )
}
