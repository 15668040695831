import UploadIcon from 'assets/images/icon-cloud-upload.png'
import axios from 'axios'
import * as S from 'pages/Submit/styles'
import SubmitLoading from 'pages/Submit/SubmitLoading'
import { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { CheckCircle } from 'react-feather'
import { useAddPopup } from 'state/application/hooks'
import { useGetPresignMutation } from 'state/daos/slice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setArtFileName, setArtUri } from 'state/submitartwork/slice'

export default function URL({ onNextClick, onBackClick }: { onNextClick: () => void; onBackClick: () => void }) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  const [uploadType, setUploadType] = useState(0)
  const addPopup = useAddPopup()

  const [error, setError] = useState('')
  const [file, setFile] = useState(null as any)
  const handleChange = (file: any) => {
    setFile(file)
  }
  const fileTypes = ['zip', 'rar']
  const [isLoading, setIsLoading] = useState(false)

  const [getPresign, { isLoading: getPreLoading }] = useGetPresignMutation()
  const [uploadPercent, setUploadPercent] = useState(0.00001)

  useEffect(() => {
    if (submitArtwork.artUri && uploadType === 0) {
      if (submitArtwork.fileName) {
        setUploadType(3)
      } else {
        setUploadType(1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className={uploadType === 1 ? 'div-block-11' : ''}>
          <div className="text-block-14">
            8. Submit your artwork* <span className="text-span-13">- </span>
            {uploadType === 0 && (
              <span className="text-span-12">
                You can upload a compressed file if it is less than 1gb, or enter a url if it is over 1gb. Accepted file
                types are zip and rar. Recommend collection number 20~1000 images. Name each image with numbers, e.g.
                1-999.
              </span>
            )}
            {uploadType === 1 && (
              <span className="text-span-12">
                Enter a url for a file over 1gb and make sure it is downloadable. Recommend 20~1000 images in the format
                of png or jpeg. Name each image with numbers, e.g. 1-999.
              </span>
            )}
            {(uploadType === 2 || uploadType === 3) && (
              <span className="text-span-12">
                make sure the compressed file is less than 1gb. Accepted file types are zip and rar. <br />
                Recommend 20~1000 images in the format of png or jpeg. Name each image with numbers, e.g. 1-999.
              </span>
            )}
            ‍<br />
          </div>
          {uploadType === 0 && (
            <div className="selection">
              <div
                onClick={() => {
                  setUploadType(1)
                }}
                className="button-4 w-button"
              >
                Enter URL
              </div>
              <div
                onClick={() => {
                  setUploadType(2)
                }}
                className="button-4 w-button"
              >
                Upload file
              </div>
            </div>
          )}
          {uploadType === 1 && (
            <S.Input
              value={submitArtwork.artUri}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              autoFocus
              onChange={(event) => {
                if (error) {
                  setError('')
                }
                const input = event.target.value
                dispatch(setArtUri(input))
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  if (submitArtwork.artUri.length > 0) {
                    onNextClick()
                  } else {
                    setError('Please input artwork url')
                  }
                }
              }}
            />
          )}
          {uploadType === 2 && (
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} maxSize={1024}>
              <div className="div-block-9">
                <img
                  src={UploadIcon}
                  loading="lazy"
                  width="45"
                  sizes="(max-width: 479px) 85vw, 45px"
                  alt="UploadIcon"
                  className="image-9"
                />
                <div className="text-block-15">
                  choose file or drag here
                  <br />
                  size limit 1gb
                  <br />
                </div>

                <div>{file && <span>{file?.name}</span>}</div>
              </div>
            </FileUploader>
          )}

          {uploadType === 3 && (
            <div className="text-block-18">
              {submitArtwork.fileName && (
                <>
                  <span>{submitArtwork.fileName} uploaded successfully.</span> <CheckCircle></CheckCircle>
                </>
              )}
            </div>
          )}
        </div>
        <SubmitLoading
          isOpen={isLoading}
          onDismiss={() => {
            console.log('')
          }}
          text={'Uploading...'}
          uploadPercent={uploadPercent}
        ></SubmitLoading>

        {error && <S.ErrorPanel>{error}</S.ErrorPanel>}
        <div
          onClick={() => {
            if (isLoading || getPreLoading) {
              return
            }

            if (uploadType === 0) {
              onBackClick()
            } else if (uploadType === 3) {
              setUploadType(2)
            } else {
              setUploadType(0)
            }
          }}
          className="previous w-button"
        >
          Previous <strong>←</strong>
        </div>
        {uploadType !== 0 && (
          <div
            onClick={() => {
              if (isLoading || getPreLoading) {
                return
              }
              if (uploadType === 1 || uploadType === 3) {
                if (submitArtwork.artUri.length > 0) {
                  onNextClick()
                } else {
                  setError('Please input artwork url')
                }
              } else if (uploadType === 2) {
                const formData = new FormData()
                formData.append('file', file, file.name)
                setUploadPercent(0.000001)
                setIsLoading(true)

                getPresign({ name: file.name })
                  .then((res: any) => {
                    const { data } = res
                    if (data && data?.code === 0) {
                      axios
                        .request({
                          method: 'PUT',
                          headers: { 'Content-Type': file.type, 'Access-Control-Allow-Origin': '*' },
                          url: data?.data.preSignUrl,
                          data: file,
                          onUploadProgress: (p) => {
                            setUploadPercent(p.loaded / p.total)
                          }
                        })
                        .then((putRet: any) => {
                          console.log(putRet)
                          setIsLoading(false)
                          if (putRet?.status === 200) {
                            setUploadType(3)
                            dispatch(setArtFileName(file.name))
                            dispatch(setArtUri(data?.data?.url))
                          } else {
                            addPopup({ error: 'Upload file failed, Please retry' })
                          }
                        })
                        .catch((err) => {
                          setIsLoading(false)
                          addPopup({ error: 'Upload file failed, Please retry' })
                        })
                    } else {
                      setIsLoading(false)
                      addPopup({ error: 'Upload file failed, Please retry' })
                    }
                  })
                  .catch((err) => {
                    addPopup({ error: 'Upload file failed, Please retry' })
                    setIsLoading(false)
                  })
              }
            }}
            className="ok-button w-button"
          >
            OK <strong className="bold-text">↵</strong>
          </div>
        )}
      </div>
    </div>
  )
}
