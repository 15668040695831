import { animated, useSpring } from '@react-spring/web'
import DefaultImage from 'assets/images/default-image.png'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import { useEffect, useRef, useState } from 'react'

export default function TokenCard({
  image,
  tokenId,
  onClick
}: {
  image: string
  tokenId: string
  onClick?: () => void
}) {
  const [isHovering, setIsHovering] = useState(false)
  const Img = useRef<HTMLImageElement>()
  const { scale, opacity } = useSpring({
    from: { scale: 1, opacity: 1 },
    scale: isHovering ? 0.95 : 1,
    opacity: isHovering ? 0.12 : 1,
    config: { duration: 200 }
  })

  const { opacity: titleOpacity } = useSpring({
    from: { opacity: 1 },
    opacity: isHovering ? 1 : 0,
    config: { duration: 200 }
  })

  const fetchData = useFetchTokenUriDataCallback()
  const [data, setData] = useState(null as any)
  useEffect(() => {
    fetchData(image)
      .then((data) => {
        setData(data)
      })
      .catch((error) => console.debug('fetching data error', error))
  }, [fetchData, image])

  return (
    <div
      className="div-block-2"
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onClick={onClick}
    >
      <animated.img
        ref={Img as any}
        style={{ scale, opacity }}
        src={data?.external_url ?? data?.image ?? DefaultImage}
        loading="lazy"
        alt=""
        onError={() => {
          if (Img.current) {
            Img.current.src = DefaultImage
          }
        }}
        className="image-5"
      />
      <animated.div
        className="text-wrapper"
        style={{
          opacity: titleOpacity
        }}
      >
        <div className="text-block-7">
          <span className="text-span-5">
            # {tokenId}
            <br />
          </span>
        </div>
      </animated.div>
    </div>
  )
}
