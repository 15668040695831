import * as S from 'pages/Submit/styles'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setWebsite } from 'state/submitartwork/slice'
import { validateUrl } from 'utils/validators'
export default function Website({ onNextClick, onBackClick }: { onNextClick: () => void; onBackClick: () => void }) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()

  const [error, setError] = useState('')
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className="div-block-11">
          <div className="text-block-14">
            6. Website <span className="text-span-13">- </span>
            <span className="text-span-12">
              This field is optional. The link entered should lead to a place that exhibits more about your presented
              project or your background as an artist. This can link to a page that is specific to your profile, your
              personal website. Make sure to enter a fully defined website link, including the https://
            </span>
            ‍<br />
          </div>
          <S.Input
            value={submitArtwork.website}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={'https://'}
            autoFocus
            onChange={(event) => {
              const input = event.target.value
              if (error) {
                setError('')
              }
              dispatch(setWebsite(input))
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (!submitArtwork.website || validateUrl(submitArtwork.website)) {
                  onNextClick()
                } else {
                  setError('Make sure to enter a fully defined website link, including the https://')
                }
              }
            }}
          />
        </div>
        {error && <S.ErrorPanel>{error}</S.ErrorPanel>}
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div
          onClick={() => {
            if (!submitArtwork.website || validateUrl(submitArtwork.website)) {
              onNextClick()
            } else {
              setError('Make sure to enter a fully defined website link, including the https://')
            }
          }}
          className="ok-button w-button"
        >
          OK <strong className="bold-text">↵</strong>
        </div>
      </div>
    </div>
  )
}
