import { Trans } from '@lingui/macro'
import useENSName from 'hooks/useENSName'
import {
  ApproveTransactionInfo,
  BuildTransactionInfo,
  DelegateTransactionInfo,
  SafeMintTransactionInfo,
  SetNewConfigTransactionInfo,
  SetNewTimerTransactionInfo,
  TransactionInfo,
  TransactionType
} from 'state/transactions/types'

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  return <Trans>Approve</Trans>
}

function DelegateSummary({ info: { delegatee } }: { info: DelegateTransactionInfo }) {
  const { ENSName } = useENSName(delegatee)
  return <Trans>Delegate voting power to {ENSName ?? delegatee}</Trans>
}

function BuildSummary({ info: { clubName, symbol } }: { info: BuildTransactionInfo }) {
  return (
    <Trans>
      Create {clubName} Club with Token {symbol}
    </Trans>
  )
}

function SafeMintSummary({ info: { to } }: { info: SafeMintTransactionInfo }) {
  return <Trans>Mint Token</Trans>
}

function SetNewTimerSummary({ info: { startTime, endTime } }: { info: SetNewTimerTransactionInfo }) {
  return <Trans>Change Timer</Trans>
}

function SetNewConfigSummary({ info: { startTime, endTime } }: { info: SetNewConfigTransactionInfo }) {
  return <Trans>Open New Deposit</Trans>
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />

    case TransactionType.DELEGATE:
      return <DelegateSummary info={info} />

    case TransactionType.BUILD:
      return <BuildSummary info={info} />

    case TransactionType.SAFE_MINT:
      return <SafeMintSummary info={info} />
    case TransactionType.NEW_TIMER:
      return <SetNewTimerSummary info={info} />
    case TransactionType.NEW_CONFIG:
      return <SetNewConfigSummary info={info} />
  }
}
