import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
// import Circle from 'assets/svg/blue-loader.svg'
import { AutoColumn, ColumnCenter } from 'components/Column'
import { RowBetween } from 'components/Row'
import { SupportedChainId } from 'constants/chains'
import { PublicUrl } from 'constants/publicurl'
import { useContext, useRef } from 'react'
import { ArrowUpCircle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner, ThemedText } from 'theme'
import { ExternalLink } from 'theme/components'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const ConfirmOrLoadingWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 20px 0;
`

export function LoadingView({
  children,
  onDismiss,
  showTip = true
}: {
  children: any
  onDismiss: () => void
  showTip?: boolean
}) {
  const Img = useRef<HTMLImageElement>()
  const { chainId } = useWeb3React()
  return (
    <ConfirmOrLoadingWrapper>
      <ConfirmedIcon>
        <CustomLightSpinner
          ref={Img as any}
          src={PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/svg/blue-loader.svg'}
          alt="loader"
          // onError={() => {
          //   if (Img.current) {
          //     Img.current.src = Circle
          //   }
          // }}
        />
      </ConfirmedIcon>
      <AutoColumn gap="10px" justify={'center'}>
        {children}
        {showTip && (
          <ThemedText.SubHeader>
            <Trans>Confirm this transaction in your wallet</Trans>
          </ThemedText.SubHeader>
        )}
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}

export function SubmittedView({
  children,
  onDismiss,
  hash
}: {
  children: any
  onDismiss: () => void
  hash: string | undefined
}) {
  const theme = useContext(ThemeContext)
  const { chainId } = useWeb3React()

  return (
    <ConfirmOrLoadingWrapper>
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIcon>
        <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} />
      </ConfirmedIcon>
      <AutoColumn gap="100px" justify={'center'}>
        {children}
        {chainId && hash && (
          <ExternalLink
            href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
            style={{ marginLeft: '4px' }}
          >
            <ThemedText.SubHeader>
              <Trans>View transaction on Explorer</Trans>
            </ThemedText.SubHeader>
          </ExternalLink>
        )}
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}
