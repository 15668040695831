import { api as generatedApi } from 'state/data/generated'

// tag that should be applied to queries that need to be invalidated when the chain changes
export const CHAIN_TAG = 'Chain'

// enhanced api to provide/invalidate tags
export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [CHAIN_TAG],
  endpoints: {
    allProjects: {
      providesTags: [CHAIN_TAG]
    },
    projectDetail: {
      providesTags: [CHAIN_TAG]
    },
    accountProjects: {
      providesTags: [CHAIN_TAG]
    },
    allTokens: {
      providesTags: [CHAIN_TAG]
    }
  }
})

export const { useAllProjectsQuery, useProjectDetailQuery, useAllTokensQuery, useAccountProjectsQuery } = api
