import uriToHttp from 'lib/utils/uriToHttp'
const dataCache = new Map<string, any>()

export default async function fetchData(dataUrl: string, noCache?: boolean): Promise<any> {
  const cached = dataCache?.get(dataUrl) // avoid spurious re-fetches
  if (cached && !noCache) {
    return cached
  }

  const urls = uriToHttp(dataUrl)
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    const isLast = i === urls.length - 1
    let response
    try {
      response = await fetch(url, { credentials: 'omit' })
    } catch (error) {
      const message = `failed to fetch list: ${dataUrl}`
      console.debug(message, error)
      if (isLast) throw new Error(message)
      continue
    }

    if (!response.ok) {
      const message = `failed to fetch list: ${dataUrl}`
      console.debug(message, response.statusText)
      if (isLast) throw new Error(message)
      continue
    }

    const json = await response.json()
    if (!noCache) {
      console.log('cached data', noCache)
      dataCache?.set(dataUrl, json)
    }
    return json
  }

  throw new Error('Unrecognized list URL protocol.')
}
