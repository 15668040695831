import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setAcceptOriginalityAck } from 'state/submitartwork/slice'

export default function OriginalAck({
  onNextClick,
  onBackClick
}: {
  onNextClick: () => void
  onBackClick: () => void
}) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (submitArtwork.acceptOriginalityAck) {
      onNextClick()
    }
  }, [onNextClick, submitArtwork.acceptOriginalityAck])
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div>
          <div className="text-block-14">
            10. Originality Acknowledgement{' '}
            <span className="text-span-14">
              - I acknowledge that my project will consist of 100% original content created by myself or my
              collaborative team and will not contain content that is purchased or requires licensing from third
              parties.
            </span>
            <br />‍<br />
          </div>
        </div>
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div
          onClick={() => {
            dispatch(setAcceptOriginalityAck(true))
            onNextClick()
          }}
          className="ok-button w-button"
        >
          Accept
        </div>
      </div>
    </div>
  )
}
