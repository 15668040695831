import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import FullScreenModal from 'components/FullScreenModal'
import { LoadingView } from 'components/ModalViews'
import { RowBetween } from 'components/Row'
import * as S from 'pages/Detail/styles'
import { useEffect, useState } from 'react'
import { useIsTransactionConfirmed } from 'state/transactions/hooks'
import { ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

interface PurchaseProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string
  refresh: () => void
}

export default function PurchaseModal({ isOpen, onDismiss, hash, refresh }: PurchaseProps) {
  const { chainId } = useWeb3React()
  const [showSuccess, setShowSuccess] = useState(false)

  const txConfirmed = useIsTransactionConfirmed(hash)

  useEffect(() => {
    if (txConfirmed) {
      setTimeout(() => {
        setShowSuccess(true)
      }, 5000)
    }
  }, [txConfirmed])

  return (
    <FullScreenModal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} customZIndex={1001}>
      <S.Container>
        {showSuccess ? (
          <AutoColumn gap="12px" justify={'center'}>
            <S.Content>Your purchase is complete!</S.Content>

            <RowBetween>
              {chainId && (
                <S.StyledExtLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
                  View on Explorer
                </S.StyledExtLink>
              )}

              <S.StyledInLink
                onClick={() => {
                  setShowSuccess(false)
                  refresh()
                }}
              >
                View your Token
              </S.StyledInLink>
            </RowBetween>
          </AutoColumn>
        ) : (
          <LoadingView onDismiss={onDismiss} showTip={false}>
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.LargeHeader>Purchasing...</ThemedText.LargeHeader>
            </AutoColumn>
          </LoadingView>
        )}
      </S.Container>
    </FullScreenModal>
  )
}
