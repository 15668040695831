import * as S from 'pages/Submit/styles'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setSocialHandle } from 'state/submitartwork/slice'
export default function SocialHandles({
  onNextClick,
  onBackClick
}: {
  onNextClick: () => void
  onBackClick: () => void
}) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className="div-block-11">
          <div className="text-block-14">
            7. Social handle <span className="text-span-13">- </span>
            <span className="text-span-12">
              This is optional, you can enter full link of your twitter, instagram, youtube or something else
            </span>
            ‍<br />
          </div>
          <S.Input
            value={submitArtwork.socialHandle}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            autoFocus
            onChange={(event) => {
              const input = event.target.value
              dispatch(setSocialHandle(input))
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onNextClick()
              }
            }}
          />
        </div>
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div onClick={onNextClick} className="ok-button w-button">
          OK <strong className="bold-text">↵</strong>
        </div>
      </div>
    </div>
  )
}
