import { useWeb3React } from '@web3-react/core'
import FullscreenIcon from 'assets/images/icon-size-fullscreen.png'
import FullscreenModal from 'components/Fullscreen'
import TokenCard from 'components/TokenCard'
import TokenImage from 'components/TokenImage'
import { OPENSEA_INFO } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { getCustomCollectionTime } from 'constants/collections'
import { formatEther } from 'ethers/lib/utils'
import { useSafeMintCallback } from 'hooks/useControllerContractCallback'
import useENSName from 'hooks/useENSName'
import { useFetchUriDataCallback } from 'hooks/useFetchDaoData'
import { useWindowSize } from 'hooks/useWindowSize'
import PurchaseModal from 'pages/Detail/purchase'
import * as S from 'pages/Detail/styles'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ReactMarkdown from 'react-markdown'
import Masonry from 'react-responsive-masonry'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useProjectDetailQuery } from 'state/data/enhanced'
import { ExternalLink, MEDIA_WIDTHS } from 'theme'
import { shortAddress } from 'utils/safeNamehash'

export default function Detail({
  match: {
    params: { projectId, tokenId }
  }
}: RouteComponentProps<{ projectId: string; tokenId: string }>) {
  const pageSize = 12
  const { account, chainId } = useWeb3React()
  const history = useHistory()
  const { data, refetch } = useProjectDetailQuery({
    id: projectId
  })

  const [allTokens, setAllTokens] = useState<number[]>([] as number[])
  const [soldTokens, setSoldTokens] = useState<number[]>([] as number[])
  const [showTokens, setShowTokens] = useState<number[]>([] as number[])

  const [filterType, setFilterType] = useState(0)
  const [showId, setShowId] = useState('')
  const [owner, setOwner] = useState('')
  const { ENSName } = useENSName(owner)
  const openseaInfo = OPENSEA_INFO[chainId ?? SupportedChainId.MAINNET]
  const [openseaUrl, setOpenseaUrl] = useState(openseaInfo)
  const fetchData = useFetchUriDataCallback()
  const [contractAddress, setContractAddress] = useState('')
  const [openSeaSlug, setOpenseaSlug] = useState('')
  const [fullImage, setFullImage] = useState('')
  const [fullscreen, setFullscreen] = useState(false)
  const [startMint, setStartMint] = useState(false)
  const [page, setPage] = useState(0)
  const [tokens, setTokens] = useState([] as number[])
  const [hasMore, setHasMore] = useState(true)
  const { width } = useWindowSize()

  const rangeArray = (start: number, end: number) =>
    Array(end - start + 1)
      .fill(0)
      .map((v, i) => i + start)

  useEffect(() => {
    if (data && data.project) {
      if ((getCustomCollectionTime(data.project.tokenContract) ?? data.project.timer) * 1000 < new Date().getTime()) {
        setStartMint(true)
        const all = rangeArray(1, data.project.maxSupply)
        setAllTokens(all)
      } else {
        setStartMint(false)
        setAllTokens([1])
        if (tokenId && parseInt(tokenId) !== 1) {
          history.push(`/detail/${data.project.id}`)
          return
        }
      }

      const soldArr = data.project.tokens.map((item: { tokenId: any }) => parseInt(item.tokenId))
      console.log(soldArr)
      setSoldTokens(soldArr)
      if (contractAddress !== data.project.tokenContract) {
        fetchData(`${openseaInfo.apiUrl}/asset_contract/${data.project.tokenContract}`)
          .then((collection) => {
            setOpenseaSlug(collection?.collection?.slug)
            setContractAddress(data.project.tokenContract)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (tokenId) {
        setShowId(tokenId)
        if (soldArr.indexOf(parseInt(tokenId)) !== -1) {
          setOwner(data.project.tokens[soldArr.indexOf(parseInt(tokenId))].owner.id)
        }
      } else if (!startMint) {
        setShowId('1')
      } else {
        let isSet = false
        for (let i = 1; i <= data.project.maxSupply; i++) {
          if (soldArr.indexOf(i) === -1) {
            setShowId(i.toString())
            isSet = true
            break
          }
        }
        if (!isSet) {
          setShowId('1')
        }
      }
    }
  }, [contractAddress, data, data?.project, fetchData, history, openseaInfo.apiUrl, startMint, tokenId])

  useEffect(() => {
    if (chainId) {
      setOpenseaUrl(OPENSEA_INFO[chainId])
    }
  }, [chainId])

  useEffect(() => {
    setPage(0)
    setHasMore(true)
    setTokens([])
    if (filterType === 2) {
      setShowTokens(soldTokens)
    } else if (filterType === 1) {
      const saleTokens = allTokens.filter((token) => {
        return soldTokens.indexOf(token) === -1
      })
      setShowTokens(saleTokens)
    } else {
      setShowTokens(allTokens)
    }
  }, [allTokens, filterType, soldTokens])

  const showOwner = useMemo(() => {
    return ENSName ?? shortAddress(owner, 6, 4)
  }, [ENSName, owner])

  const { mintCallback } = useSafeMintCallback(data?.project?.controllerContract)
  const addPopup = useAddPopup()
  const toggleWalletModal = useWalletModalToggle()

  const [txHash, setTxHash] = useState<string>('')

  async function onBuild() {
    // if callback not returned properly ignore
    if (!account) toggleWalletModal()
    if (!mintCallback) return
    try {
      const hash = await mintCallback(account ?? '', showId, data?.project?.price)
      setTxHash(hash ?? '')
    } catch (error) {
      addPopup({
        error:
          'Mint Failed, Please check error: ' + (error.reason ? error.reason : error.message ? error.message : error)
      })
    }
  }

  return (
    <>
      <FullscreenModal isOpen={fullscreen} onDismiss={() => setFullscreen(false)}>
        <S.Hero>
          <S.Video>
            <img src={fullImage} alt={'fullImage'} />
          </S.Video>
        </S.Hero>
      </FullscreenModal>

      <PurchaseModal
        isOpen={!!txHash}
        onDismiss={() => console.log('')}
        hash={txHash}
        refresh={() => {
          refetch()
          if (tokenId) {
            setTxHash('')
          } else {
            setTxHash('')
            history.push(`/detail/${data.project.id}/${showId}`)
          }
        }}
      ></PurchaseModal>
      <div className="section-2 sold-warpper wf-section">
        <div className="feature-image-wrapper w-container">
          {data && data.project && showId && (
            <TokenImage
              uri={data.project.baseUri + showId}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, 88vw"
              alt=""
              className="image-7"
              callback={(image) => setFullImage(image)}
            ></TokenImage>
          )}
        </div>
        {fullImage && (
          <img
            onClick={() => setFullscreen(true)}
            src={FullscreenIcon}
            loading="lazy"
            width="45"
            height="45"
            alt="FullscreenIcon"
            className="image-12"
          />
        )}
      </div>
      <div className="print title detail-page wf-section">
        <div className="container-7 w-container">
          <div className="columns-2 w-row">
            <S.InfoContainer className="column-7 w-col w-col-6">
              {data && data.project && (
                <S.Info
                  className="text-block-5"
                  width={25}
                  len={data.project.name.length + data?.project?.artistName.length + 8}
                >
                  {data.project.name} # {showId.padStart(3, '0') ?? ''} •{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`/profile/${data.project.creator[0].account.id}`)
                    }}
                  >
                    <strong>
                      <em className="italic-text">{data?.project?.artistName}</em>
                    </strong>
                  </span>
                </S.Info>
              )}
            </S.InfoContainer>

            {soldTokens.indexOf(parseInt(showId)) !== -1 ? (
              <S.OwnerContainer className="column-8 w-col w-col-4">
                <S.Info
                  className="text-block-5"
                  width={12.5}
                  style={{ cursor: 'pointer' }}
                  len={showOwner.length}
                  onClick={() => {
                    history.push(`/profile/${owner}`)
                  }}
                >
                  {showOwner && <span className="text-span-11">owner: {showOwner}</span>}
                </S.Info>
              </S.OwnerContainer>
            ) : (
              <>
                <S.PriceContainer className="column-3 w-col w-col-3">
                  {data && data.project && (
                    <S.Info width={12.5} className="text-block-5" len={formatEther(data?.project?.price).length + 8}>
                      Price {formatEther(data?.project?.price)} Ξ
                    </S.Info>
                  )}
                </S.PriceContainer>
                {startMint && (
                  <S.MintContainer
                    className="column-8 w-col w-col-3"
                    onClick={() => {
                      onBuild()
                    }}
                  >
                    <div className="button w-button">Mint </div>
                  </S.MintContainer>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="section-3 wf-section">
        <div className="w-container">
          {data && data.project && (
            <div className="text-block-6">
              <ReactMarkdown source={data.project.description} />
              <br />
              AI Engine: {data.project.engine}
              <br />
              <br />
              License:{' '}
              <ExternalLink href={'https://www.niftylicense.org/'} className="text-span">
                {data?.project?.license}
              </ExternalLink>
              <br />
              <br />
              {(soldTokens.indexOf(parseInt(showId)) !== -1 ||
                (soldTokens.indexOf(parseInt(showId)) === -1 && openSeaSlug)) && (
                <>
                  View on{' '}
                  <ExternalLink
                    href={
                      soldTokens.indexOf(parseInt(showId)) !== -1
                        ? `${openseaUrl.tokenUrl}/${data.project.tokenContract}/${showId}`
                        : `${openseaInfo.collectionUrl}/${openSeaSlug}`
                    }
                    className="text-span-2"
                  >
                    OpenSea
                  </ExternalLink>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="already-minted-nft-display-title wf-section">
        <div className="w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <div className="text-block-9">collection</div>
            </div>
            <div className="w-col w-col-2" onClick={() => setFilterType(0)}>
              <div className={filterType === 0 ? 'text-block-9 all detail-filter-active' : 'text-block-9 all '}>
                all  <span className="text-span-8">{allTokens.length}</span>
              </div>
            </div>
            <div className="w-col w-col-2" onClick={() => setFilterType(1)}>
              <div className={filterType === 1 ? 'text-block-9 all detail-filter-active' : 'text-block-9 all'}>
                for sale <span className="text-span-9">{allTokens.length - soldTokens.length}</span>
              </div>
            </div>
            <div className="w-col w-col-2" onClick={() => setFilterType(2)}>
              <div className={filterType === 2 ? 'text-block-9 all detail-filter-active' : 'text-block-9 all'}>
                sold <span className="text-span-10">{soldTokens.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery detail-page wf-section">
        <div className="already-mint-collections w-container">
          <div className="minted w-row">
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              threshold={250}
              loadMore={() => {
                if ((page + 1) * pageSize > showTokens.length) {
                  setHasMore(false)
                  setTokens(tokens.concat(showTokens.slice(page * pageSize, showTokens.length)))
                } else {
                  setTokens(tokens.concat(showTokens.slice(page * pageSize, (page + 1) * pageSize)))
                }
                setPage(page + 1)
              }}
              hasMore={hasMore}
              useWindow={true}
              loader={
                <div className="container-10 w-container" key={2}>
                  <div>{/* <div className="text-block-12">Load more...</div> */}</div>
                </div>
              }
            >
              <Masonry
                columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3}
                gutter="20px"
                className="collection wf-section"
                key={1}
              >
                {tokens &&
                  tokens.map((tokenId: any) => {
                    return (
                      <TokenCard
                        key={tokenId}
                        image={data.project.baseUri + tokenId}
                        tokenId={tokenId}
                        onClick={() => {
                          setShowId('')
                          history.replace(`/detail/${data.project.id}/${tokenId}`)
                        }}
                      ></TokenCard>
                    )
                  })}
              </Masonry>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  )
}
