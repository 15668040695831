import styled from 'styled-components/macro'

export const Input = styled.input`
  font-size: 1.25rem;
  outline: none;
  border: none;
  width: 0;
  background-color: transparent;
  transition: color 300ms step-start;
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

export const TextArea = styled.textarea`
  font-size: 1.25rem;
  outline: none;
  border: none;
  background-color: transparent;
  transition: color 300ms step-start;
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  height: fit-content;
  font-weight: 500;
  width: 100%;
  padding: 0px;
`

export const ErrorPanel = styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.red1};
`

export const Container = styled.div`
  width: 50vw;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  over-flow: hidden;
  background-color: #222;
  border-radius: 2vw;
  border: 1px solid #ededed;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 80vw;
    height: 15vh;
    border-radius: 2vh;
  `};
`

export const SuccessBtnPanel = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5%;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`
