import TokenCard from 'components/TokenCard'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-responsive-masonry'
import { useHistory } from 'react-router-dom'
import { useAllTokensQuery } from 'state/data/generated'
import { MEDIA_WIDTHS } from 'theme'

export default function OwnTokensPanel({ address }: { address: string }) {
  const pageSize = 9
  const history = useHistory()
  const { width } = useWindowSize()
  const [skip, setSkip] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [projects, setProjects] = useState([] as any[])
  const { data, isFetching } = useAllTokensQuery({
    account: address.toLowerCase(),
    first: pageSize,
    skip
  })

  useEffect(() => {
    if (data && projects) {
      if (data.tokens && (data.tokens.length === 0 || data.tokens.length < pageSize)) setHasMore(false)
      setProjects(projects.concat(data.tokens))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  return (
    <div className="collection wf-section">
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        threshold={250}
        loadMore={() => {
          if (!isFetching) setSkip(skip + pageSize)
        }}
        hasMore={hasMore}
        useWindow={true}
        loader={
          <div className="container-10 w-container" key={2}>
            <div>{/* <div className="text-block-12">Load more...</div> */}</div>
          </div>
        }
      >
        <Masonry columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3} gutter="20px" key={1}>
          {projects &&
            projects.map((item: any, index: any) => {
              return (
                <TokenCard
                  key={item.project.id}
                  tokenId={item.tokenId}
                  image={item.project.baseUri + item.tokenId}
                  onClick={() => {
                    history.push(`/detail/${item.project.id}/${item.tokenId}`)
                  }}
                ></TokenCard>
              )
            })}
        </Masonry>
      </InfiniteScroll>
    </div>
  )
}
