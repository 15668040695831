import FullScreenModal from 'components/FullScreenModal'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`
const StyledModal = styled(FullScreenModal)`
  &[data-reach-dialog-overlay] {
    z-index: 1001;
  }
`

export default function FullscreenModal({
  isOpen,
  onDismiss,
  children
}: React.PropsWithChildren<{ isOpen: boolean; onDismiss: () => void }>) {
  return (
    <StyledModal customZIndex={1001} isOpen={isOpen} onDismiss={onDismiss} minHeight={false} maxHeight={100}>
      <Wrapper>{children}</Wrapper>
    </StyledModal>
  )
}
