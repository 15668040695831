import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { AutoColumn } from 'components/Column'
import FullScreenModal from 'components/FullScreenModal'
import { LoadingView } from 'components/ModalViews'
import * as S from 'pages/Submit/styles'
import styled from 'styled-components/macro'

interface SubmitLoadingProps {
  isOpen: boolean
  onDismiss: () => void
  text: string
  uploadPercent?: number
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  marginTop: 10,
  paddingTop: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
    border: '1px solid #ededed'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ededed'
  }
}))

export default function SubmitLoading({ isOpen, onDismiss, text, uploadPercent }: SubmitLoadingProps) {
  return (
    <FullScreenModal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} customZIndex={1001}>
      <S.Container>
        {uploadPercent && uploadPercent >= 0 ? (
          <S.Content>
            <S.Title>Uploading your artwork...</S.Title>
            <BorderLinearProgress variant="determinate" value={uploadPercent * 100} />
          </S.Content>
        ) : (
          <LoadingView onDismiss={onDismiss} showTip={false}>
            <AutoColumn gap="12px" justify={'center'}>
              <S.Title>{text}</S.Title>
            </AutoColumn>
          </LoadingView>
        )}
      </S.Container>
    </FullScreenModal>
  )
}
