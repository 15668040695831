import { ResizingTextArea } from 'components/TextInput'
import * as S from 'pages/Submit/styles'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setDescription } from 'state/submitartwork/slice'
import { fnGetCpmisWords, fnGetPrefixWords } from 'utils/validators'
export default function Description({
  onNextClick,
  onBackClick
}: {
  onNextClick: () => void
  onBackClick: () => void
}) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()

  const [error, setError] = useState('')
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className="div-block-11">
          <div className="text-block-14">
            4. Collection Description* <span className="text-span-13">- </span>
            <span className="text-span-12">
              the description should summarise your collection displayed — including inspiration, story, color palette
              influences, themes, techniques and creative credits if any. The description should be between 80 and 200
              words.{' '}
            </span>
            <span>&nbsp;({200 - fnGetCpmisWords(submitArtwork.description)})</span>
            ‍<br />
          </div>
          <ResizingTextArea
            value={submitArtwork.description}
            onUserInput={(value) => {
              if (error) {
                setError('')
              }
              let input = value
              if (fnGetCpmisWords(value) > 200) {
                input = fnGetPrefixWords(value, 200)
                setError('The description should be between 80 and 200 words.')
              }
              dispatch(setDescription(input))
            }}
            placeholder={''}
            fontSize={'1.25rem'}
          />
        </div>
        {error && <S.ErrorPanel>{error}</S.ErrorPanel>}
        <div onClick={onBackClick} className="previous w-button">
          Previous <strong>←</strong>
        </div>
        <div
          onClick={() => {
            const len = fnGetCpmisWords(submitArtwork.description)
            if (len >= 80 && len <= 200) {
              onNextClick()
            } else {
              setError('The description should be between 80 and 200 words.')
            }
          }}
          className="ok-button w-button"
        >
          OK <strong className="bold-text">↵</strong>
        </div>
      </div>
    </div>
  )
}
