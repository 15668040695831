import * as S from 'pages/Submit/styles'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setCreatorNames } from 'state/submitartwork/slice'
export default function CreatorNames({ onNextClick }: { onNextClick: () => void }) {
  const submitArtwork = useAppSelector((state) => state.submitArtwork)
  const dispatch = useAppDispatch()
  const [error, setError] = useState('')
  return (
    <div className="submit-1 wf-section">
      <div className="submit-container w-container">
        <div className="div-block-11">
          <div className="text-block-14">
            1. Creator Name(s)*{' '}
            <span className="text-span-18">
              - This is how your name will appear on AW3C. What you choose to enter here is up to your personal
              preference.
            </span>
            <span>&nbsp;({30 - submitArtwork.creatorNames.length})</span>
            ‍<br />
          </div>
          <S.Input
            value={submitArtwork.creatorNames}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            autoFocus
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (submitArtwork.creatorNames.length > 0 && submitArtwork.creatorNames.length <= 30) {
                  onNextClick()
                } else {
                  setError('Please input correct creator name(s)')
                }
              }
            }}
            onChange={(event) => {
              if (error) {
                setError('')
              }
              let input = event.target.value
              if (input.length > 30) {
                input = input.substring(0, 30)
                setError('The creator name(s) should be no more than 30 characters.')
              }
              dispatch(setCreatorNames(input))
            }}
          />
        </div>
        {error && <S.ErrorPanel>{error}</S.ErrorPanel>}
        <div
          onClick={() => {
            if (submitArtwork.creatorNames.length > 0) {
              onNextClick()
            } else {
              setError('Please input creator name(s)')
            }
          }}
          className="ok-button w-button"
        >
          OK <strong className="bold-text">↵</strong>
        </div>
      </div>
    </div>
  )
}
