import { useWeb3React } from '@web3-react/core'
import Logo from 'assets/images/Logo.png'
import Web3Status from 'components/Web3Status'
import { coinbaseWallet } from 'connectors'
import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet, updateUserInfo } from 'state/user/reducer'

export default function Header() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { account, connector } = useWeb3React()
  const active = useMemo(() => {
    return location.pathname
  }, [location.pathname])

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className="container-3 w-container">
        <div
          onClick={() => {
            history.push('/')
          }}
          aria-current="page"
          className="brand w-nav-brand w--current"
        >
          <h2 className="heading-2">
            <img src={Logo} width="120" alt="AW3C" />
          </h2>
        </div>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <div
            onClick={() => {
              history.push('/about')
            }}
            className={`nav-link w-nav-link ${active === '/about' ? 'w--current' : ''}`}
          >
            about
          </div>
          <div
            onClick={() => {
              history.push('/collection')
            }}
            className={`nav-link w-nav-link ${active === '/collection' ? 'w--current' : ''}`}
          >
            collection
          </div>
          <div
            onClick={() => {
              history.push('/submit')
            }}
            className={`nav-link-3 w-nav-link ${active === '/submit' ? 'w--current' : ''}`}
          >
            submit artwork
          </div>
          <div className={`nav-link-3 w-nav-link ${account ? 'w--contected' : ''}`}>
            <Web3Status />
          </div>
        </nav>
        <div
          className={`menu-button w-nav-button ${menuOpen ? 'w--open' : ''}`}
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <div className="icon w-icon-nav-menu"></div>
        </div>
      </div>
      {menuOpen && (
        <div
          className="w-nav-overlay"
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open>
            <div
              onClick={() => {
                history.push('/about')
              }}
              className={`nav-link w-nav-link w--nav-link-open`}
            >
              about
            </div>
            <div
              onClick={() => {
                history.push('/collection')
              }}
              className={`nav-link w-nav-link w--nav-link-open`}
            >
              collection
            </div>
            <div
              onClick={() => {
                history.push('/submit')
              }}
              className={`nav-link-3 w-nav-link w--nav-link-open`}
            >
              submit artwork
            </div>
            {account && (
              <>
                <div
                  onClick={() => {
                    history.push(`/profile/${account}`)
                  }}
                  className={`nav-link-3 w-nav-link w--nav-link-open`}
                >
                  profile
                </div>
                <div
                  onClick={() => {
                    if (connector.deactivate) {
                      connector.deactivate()

                      // Coinbase Wallet SDK does not emit a disconnect event to the provider,
                      // which is what web3-react uses to reset state. As a workaround we manually
                      // reset state.
                      if (connector === coinbaseWallet) {
                        connector.resetState()
                      }
                    } else {
                      connector.resetState()
                    }

                    dispatch(updateSelectedWallet({ wallet: undefined }))
                    dispatch(updateUserInfo({ userInfo: null }))
                  }}
                  className={`nav-link-3 w-nav-link w--nav-link-open`}
                >
                  logout
                </div>
              </>
            )}
            {/* <div className={`nav-link-3 w-nav-link w--nav-link-open`}> */}
            <Web3Status />
            {/* </div> */}
          </nav>
        </div>
      )}
    </>
  )
}
