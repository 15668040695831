import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import MetamaskIcon from 'assets/images/metamask.png'
import TallyIcon from 'assets/images/tally.png'
import { ReactComponent as Close } from 'assets/svg/x.svg'
import AccountDetails from 'components/AccountDetails'
import { sendEvent } from 'components/Analytics'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import Option from 'components/WalletModal/Option'
import PendingView from 'components/WalletModal/PendingView'
import { fortmatic, getWalletForConnector, injected } from 'connectors'
import { SupportedChainId } from 'constants/chains'
import { PublicUrl } from 'constants/publicurl'
import { SUPPORTED_WALLETS } from 'constants/wallet'
import { useCallback, useEffect, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import uuid from 'react-uuid'
import { useAddPopup, useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useLoginBySignedMutation } from 'state/daos/slice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateSelectedWallet, updateUserInfo } from 'state/user/reducer'
import { updateWalletError } from 'state/wallet/reducer'
import styled from 'styled-components/macro'
import { isMobile } from 'utils/userAgent'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  ACCOUNT: 'account',
  PENDING: 'pending'
}

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
  ENSName
}: {
  pendingTransactions: string[] // hashes of pending
  confirmedTransactions: string[] // hashes of confirmed
  ENSName?: string
}) {
  const dispatch = useAppDispatch()
  const { connector, account, provider, chainId } = useWeb3React()
  const addPopup = useAddPopup()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingConnector, setPendingConnector] = useState<Connector | undefined>()
  const pendingError = useAppSelector((state) =>
    pendingConnector ? state.wallet.errorByWallet[getWalletForConnector(pendingConnector)] : undefined
  )
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const openOptions = useCallback(() => {
    setWalletView(WALLET_VIEWS.OPTIONS)
  }, [setWalletView])

  useEffect(() => {
    if (walletModalOpen) {
      setWalletView(account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS)
    }
  }, [walletModalOpen, setWalletView, account])
  const [loginBySigned] = useLoginBySignedMutation()

  useEffect(() => {
    if (provider && account) {
      if (currentUserInfo && currentUserInfo?.account === account) {
        console.log('the same account')
        if (currentUserInfo.accessExpire > new Date().getTime() / 1000) {
          console.log('no Expire')
          return
        }
      }
      const message = `Welcome to AW3C!\n\nClick to sign in and accept the AW3C Terms of Service: https://aw3c.xyz\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`
      provider
        .getSigner(account)
        .signMessage(message)
        .then((signature) => {
          loginBySigned({ address: account, message, signature })
            .then((response: any) => {
              if (response?.data.code === 0) {
                dispatch(updateUserInfo({ userInfo: { ...response.data.data, account } }))
              } else {
                addPopup({ error: 'Login failed. Please retry.' })
              }
            })
            .catch((err) => {
              addPopup({ error: 'Login failed. Please retry.' })
            })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, addPopup, dispatch, loginBySigned])

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateWalletError({ wallet: getWalletForConnector(pendingConnector), error: undefined })
      setPendingConnector(undefined)
    }
  }, [pendingConnector, walletView])

  const tryActivation = useCallback(
    async (connector: Connector) => {
      const wallet = getWalletForConnector(connector)

      // log selected wallet
      sendEvent({
        category: 'Wallet',
        action: 'Change Wallet',
        label: wallet
      })

      try {
        // Fortmatic opens it's own modal on activation to log in. This modal has a tabIndex
        // collision into the WalletModal, so we special case by closing the modal.
        if (connector === fortmatic) {
          toggleWalletModal()
        }

        setPendingConnector(connector)
        setWalletView(WALLET_VIEWS.PENDING)
        dispatch(updateWalletError({ wallet, error: undefined }))

        await connector.activate()

        dispatch(updateSelectedWallet({ wallet }))
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`)
        dispatch(updateWalletError({ wallet, error: error.message }))
      }
    },
    [dispatch, toggleWalletModal]
  )

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetaMask = !!window.ethereum?.isMetaMask
    const isTally = !!window.ethereum?.isTally
    const isCoinbaseWallet = !!window.ethereum?.isCoinbaseWallet
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]

      const optionProps = {
        isActive: option.connector === connector,
        id: `connect-${key}`,
        link: option.href,
        header: option.name,
        color: option.color,
        key,
        icon: PublicUrl(chainId ?? SupportedChainId.MAINNET) + option.iconURL
      }

      // check for mobile options
      if (isMobile) {
        if (
          (!window.web3 && !window.ethereum && option.mobile) ||
          (isMetaMask && option.name === 'MetaMask') ||
          (isCoinbaseWallet && option.name === 'Coinbase Wallet')
        ) {
          return (
            <Option
              {...optionProps}
              onClick={() => {
                if (!option.href && !!option.connector) {
                  tryActivation(option.connector)
                }
              }}
              subheader={null}
            />
          )
        }
        return null
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={<Trans>Install MetaMask</Trans>}
                subheader={null}
                link={'https://metamask.io/'}
                icon={MetamaskIcon}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetaMask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetaMask) {
          return null
        } else if (option.name === 'Injected' && isTally) {
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              onClick={() => {
                option.connector === connector
                  ? setWalletView(WALLET_VIEWS.ACCOUNT)
                  : !option.href && option.connector && tryActivation(option.connector)
              }}
              color={'#E8831D'}
              header={<Trans>Tally</Trans>}
              isActive={option.connector === connector}
              subheader={null}
              link={null}
              icon={TallyIcon}
            />
          )
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            {...optionProps}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && option.connector && tryActivation(option.connector)
            }}
            subheader={null} //use option.descriptio to bring back multi-line
          />
        )
      )
    })
  }

  function getModalContent() {
    if (walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          ENSName={ENSName}
          openOptions={openOptions}
        />
      )
    }

    let headerRow
    if (walletView === WALLET_VIEWS.PENDING) {
      headerRow = null
    } else if (walletView === WALLET_VIEWS.ACCOUNT || !!account) {
      headerRow = (
        <HeaderRow color="blue">
          <HoverText onClick={() => setWalletView(account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS)}>
            <ArrowLeft />
          </HoverText>
        </HeaderRow>
      )
    } else {
      headerRow = (
        <HeaderRow>
          <HoverText>
            <Trans>Connect a wallet</Trans>
          </HoverText>
        </HeaderRow>
      )
    }

    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            {walletView === WALLET_VIEWS.PENDING && pendingConnector && (
              <PendingView
                openOptions={openOptions}
                connector={pendingConnector}
                error={!!pendingError}
                tryActivation={tryActivation}
              />
            )}
            {walletView !== WALLET_VIEWS.PENDING && <OptionGrid data-testid="option-grid">{getOptions()}</OptionGrid>}
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
